import AndroidSvg from "../../assets/androidIcon.svg";

function AndroidIcon({ width }) {
  return (
    <a href="/Score4All.apk" download rel="noreferrer">
      <img
        src={AndroidSvg}
        alt="Android app Link "
        width={width}
        className="cursor-pointer"
      />
    </a>
  );
}

export default AndroidIcon;

import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import { CREATE_ACCOUNT_MODAL, LOGIN_MODAL } from '../extras/constants';

import { connect } from 'react-redux';
import AndroidIcon from '../components/social/AndroidIcon';
import { logoutUser } from '../redux/actions/auth';
import { showModal } from '../redux/actions/modal';
const Navbar = ({ showModal, logoutUser, auth }) => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(location)
  return (
    <div className="z-50">
      <nav className="shadow-md z-50 bg-white">
        <div className="max-w-6xl mx-auto px-4">
          <div className="flex justify-between">
            <div className="flex items-center space-x-7">
              <div className="flex items-center">
                <Link to="/" className="flex items-center">
                  <p className="text-3xl">
                  </p>
                  <img className='w-28 my-4' src={"/s4a/png/logo-no-background.png"} alt="Logo" />
                </Link>
              </div>
              <div className="hidden md:flex items-center space-x-4">
                {/* <Link
                  to="/contactus"
                  className={`py-4 px-2 ${location.pathname === '/contactus'
                    ? 'border-b-4 border-[#F2994A] text-app-secondary'
                    : ' text-gray-500 hover:text-[#F2994A] transition duration-300'
                    } font-semibold `}
                >
                  Contact Us
                </Link> */}
              </div>
            </div>
            <div className="hidden md:flex  items-center space-x-3">
              <button
                onClick={() =>
                  window.open("https://score4all.in/", "_blank")
                }
                className="py-2 px-2 text-white bg-app-primary font-bold rounded hover:bg-app-secondary transition duration-300"
              >
                Continue to Score4All
              </button>
            </div>

          </div>
        </div >
        <div
          className={`${!mobileMenu && 'hidden'} md:hidden mobile-menu z-50`}
        >
          <ul className="">
            <li className="active">
              <Link
                to="/"
                onClick={() => setMobileMenu(!mobileMenu)}
                className=" block text-sm px-5 py-4 hover:bg-app-secondary transition duration-300"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/mymatches"
                onClick={() => setMobileMenu(!mobileMenu)}
                className=" block text-sm px-5 py-4 hover:bg-app-secondary transition duration-300"
              >
                My Matches
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                onClick={() => setMobileMenu(!mobileMenu)}
                className=" block text-sm  px-5 py-4 hover:bg-app-secondary transition duration-300"
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="/contactus"
                onClick={() => setMobileMenu(!mobileMenu)}
                className=" block text-sm px-5 py-4 hover:bg-app-secondary transition duration-300"
              >
                Contact Us
              </Link>
            </li>

            {auth.user ? (
              <>
                <li>
                  <button
                    onClick={() => logoutUser()}
                    className="block text-sm text-left px-5 py-4 hover:bg-app-secondary transition duration-300 w-full"
                  >
                    Logout
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => logoutUser()}
                    className="block text-sm text-left px-5 py-4 hover:bg-black transition duration-300 w-full"
                  >
                    New Match
                  </button>
                </li>
              </>
            ) : (
              <>
                <li>
                  <button
                    onClick={() =>
                      showModal({
                        modalType: LOGIN_MODAL,
                      })
                    }
                    className=" block text-sm px-5 py-4 hover:bg-app-secondary transition duration-300 w-full text-left"
                  >
                    Log In
                  </button>
                </li>
                <li>
                  <button
                    onClick={() =>
                      showModal({
                        modalType: CREATE_ACCOUNT_MODAL,
                        modalTitle: ``,
                        modalSubTitle: '',
                      })
                    }
                    className=" block text-sm px-5 py-4 hover:bg-app-secondary transition duration-300 w-full text-left"
                  >
                    Sign Up
                  </button>
                </li>
              </>
            )}

            <li className="flex gap-4 text-sm px-5 py-4 ">
              <AndroidIcon width={'30px'} />
              {/* <IOSIcon /> */}
            </li>
          </ul>
        </div>
      </nav >
    </div >
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showModal: (kind) => dispatch(showModal(kind)),
    logoutUser: () => dispatch(logoutUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);

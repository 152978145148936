import React from 'react';
import { useLocation } from 'react-router';

const MatchDetailLoadingCard = () => {
  const location = useLocation();

  return (
    <div className='2xl:w-[70%] m-auto px-5 my-10'>
      <div className="rounded-xl w-full bg-white m-auto mb-7 py-5 drop-shadow-xl animate-pulse mt-5"
      >
        <div className='px-5'>
          <div className="px-6 pb-0.5 text-lg md:text-xl bg-gray-400 animate-pulse w-[20%] h-4"></div>
        </div>
        <div className='flex flex-col md:flex-row'>
          <div className="h-40 rounded-l-md w-full bg-[#FFD500] m-auto mb-7 py-5 drop-shadow-xl animate-pulse mt-5">
            <h1 className='font-semibold text-xl pl-4'>Scores</h1>
            <div className='w-full h-[2px] bg-[#FFD500]'></div>
            <div className="my-3 px-6 pb-0.5 text-lg md:text-xl bg-gray-400 animate-pulse w-[95%] m-auto h-4"></div>
            <div className="my-3 px-6 pb-0.5 text-lg md:text-xl bg-gray-400 animate-pulse w-[95%] m-auto h-4"></div>
            <div className="my-3 px-6 pb-0.5 text-lg md:text-xl bg-gray-400 animate-pulse w-[95%] m-auto h-4"></div>
          </div>
          <div className="h-40 rounded-l-md w-full bg-white m-auto mb-7 py-5 drop-shadow-xl animate-pulse mt-5">
            <h1 className='font-semibold text-xl pl-4'>Events</h1>
            <div className='w-full h-[2px] bg-[#FFD500]'></div>
            <div className="my-3 px-6 pb-0.5 text-lg md:text-xl bg-gray-400 animate-pulse w-[95%] m-auto h-4"></div>
            <div className="my-3 px-6 pb-0.5 text-lg md:text-xl bg-gray-400 animate-pulse w-[95%] m-auto h-4"></div>
            <div className="my-3 px-6 pb-0.5 text-lg md:text-xl bg-gray-400 animate-pulse w-[95%] m-auto h-4"></div>
          </div>
        </div>
        {
          location.pathname.includes('update') ? (
            <div className='flex flex-col md:flex-row items-center justify-around'>
              <div className="w-full md:w-[30%] h-48 rounded-l-md bg-white m-auto py-5 drop-shadow-xl animate-pulse">
                <h1 className="px-6 pb-0.5 text-lg md:text-xl bg-gray-400 animate-pulse w-full md:w-[30%] h-4 my-2 m-auto"></h1>
                <div className='w-full h-[2px] bg-[#FFD500]'></div>
                <table className='w-full border-collapse'>
                  <tr>
                    <th className='text-center p-2 border-r-2 border-b-2 boder-[#ddd]'>Player</th>
                    <th className='text-center p-2 border-r-2 border-b-2 boder-[#ddd]'>Score</th>
                    <th className='text-center p-2 border-r-2 border-b-2 boder-[#ddd]'>Foul</th>
                  </tr>
                  <tr>
                    <td className="w-[10%] h-6 px-6 pb-0.5 text-lg md:text-xl bg-gray-400 animate-pulse my-2 m-auto"></td>
                    <td className="w-[10%] h-6 px-6 pb-0.5 text-lg md:text-xl bg-gray-400 animate-pulse my-2 m-auto"></td>
                    <td className="w-[10%] h-6 px-6 pb-0.5 text-lg md:text-xl bg-gray-400 animate-pulse my-2 m-auto"></td>
                  </tr>
                  <br />
                  <tr>
                    <td className="w-[10%] h-6 px-6 pb-0.5 text-lg md:text-xl bg-gray-400 animate-pulse my-2 m-auto"></td>
                    <td className="w-[10%] h-6 px-6 pb-0.5 text-lg md:text-xl bg-gray-400 animate-pulse my-2 m-auto"></td>
                    <td className="w-[10%] h-6 px-6 pb-0.5 text-lg md:text-xl bg-gray-400 animate-pulse my-2 m-auto"></td>
                  </tr>
                </table>
              </div>
              <div className='w-[30%] flex flex-wrap'>
                <div className="w-[45%] mb-4 h-30 rounded-l-md bg-white m-auto py-5 drop-shadow-xl animate-pulse">
                  <div className="w-[90%] h-2 px-6 text-lg md:text-xl bg-gray-400 animate-pulse my-2 m-auto">
                  </div>
                  <div className="w-[90%] h-2 px-6 text-lg md:text-xl bg-gray-400 animate-pulse my-2 m-auto">
                  </div>
                </div>
                <div className="w-[45%] mb-4 h-30 rounded-l-md bg-white m-auto py-5 drop-shadow-xl animate-pulse">
                  <div className="w-[90%] h-2 px-6 text-lg md:text-xl bg-gray-400 animate-pulse my-2 m-auto">
                  </div>
                  <div className="w-[90%] h-2 px-6 text-lg md:text-xl bg-gray-400 animate-pulse my-2 m-auto">
                  </div>
                </div>
                <div className="w-[45%] mb-4 h-30 rounded-l-md bg-white m-auto py-5 drop-shadow-xl animate-pulse">
                  <div className="w-[90%] h-2 px-6 text-lg md:text-xl bg-gray-400 animate-pulse my-2 m-auto">
                  </div>
                  <div className="w-[90%] h-2 px-6 text-lg md:text-xl bg-gray-400 animate-pulse my-2 m-auto">
                  </div>
                </div>
                <div className="w-[45%] mb-4 h-30 rounded-l-md bg-white m-auto py-5 drop-shadow-xl animate-pulse">
                  <div className="w-[90%] h-2 px-6 text-lg md:text-xl bg-gray-400 animate-pulse my-2 m-auto">
                  </div>
                  <div className="w-[90%] h-2 px-6 text-lg md:text-xl bg-gray-400 animate-pulse my-2 m-auto">
                  </div>
                </div>
                
              </div>
              <div className="w-full md:w-[30%] h-48 rounded-l-md bg-white m-auto py-5 drop-shadow-xl animate-pulse ">
                <h1 className="px-6 pb-0.5 text-lg md:text-xl bg-gray-400 animate-pulse w-full md:w-[30%] h-4 my-2 m-auto"></h1>
                <div className='w-full h-[2px] bg-[#FFD500]'></div>
                <table className='w-full border-collapse'>
                  <tr>
                    <th className='text-center p-2 border-r-2 border-b-2 boder-[#ddd]'>Player</th>
                    <th className='text-center p-2 border-r-2 border-b-2 boder-[#ddd]'>Score</th>
                    <th className='text-center p-2 border-r-2 border-b-2 boder-[#ddd]'>Foul</th>
                  </tr>
                  <tr>
                    <td className="w-[10%] h-6 px-6 pb-0.5 text-lg md:text-xl bg-gray-400 animate-pulse my-2 m-auto"></td>
                    <td className="w-[10%] h-6 px-6 pb-0.5 text-lg md:text-xl bg-gray-400 animate-pulse my-2 m-auto"></td>
                    <td className="w-[10%] h-6 px-6 pb-0.5 text-lg md:text-xl bg-gray-400 animate-pulse my-2 m-auto"></td>
                  </tr>
                  <br />
                  <tr>
                    <td className="w-[10%] h-6 px-6 pb-0.5 text-lg md:text-xl bg-gray-400 animate-pulse my-2 m-auto"></td>
                    <td className="w-[10%] h-6 px-6 pb-0.5 text-lg md:text-xl bg-gray-400 animate-pulse my-2 m-auto"></td>
                    <td className="w-[10%] h-6 px-6 pb-0.5 text-lg md:text-xl bg-gray-400 animate-pulse my-2 m-auto"></td>
                  </tr>
                </table>
              </div>
            </div>
          ) : (
            <div className='flex flex-col md:flex-row items-center justify-around'>
              <div className="w-full md:w-[40%] h-48 rounded-l-md bg-white m-auto py-5 drop-shadow-xl animate-pulse mt-5">
                <h1 className="px-6 pb-0.5 text-lg md:text-xl bg-gray-400 animate-pulse w-full md:w-[40%] h-4 my-2 m-auto"></h1>
                <div className='w-full h-[2px] bg-[#FFD500]'></div>
                <table className='w-full border-collapse'>
                  <tr>
                    <th className='text-center p-2 border-r-2 border-b-2 boder-[#ddd]'>Player</th>
                    <th className='text-center p-2 border-r-2 border-b-2 boder-[#ddd]'>Score</th>
                    <th className='text-center p-2 border-r-2 border-b-2 boder-[#ddd]'>Foul</th>
                  </tr>
                  <tr>
                    <td className="w-[10%] h-6 px-6 pb-0.5 text-lg md:text-xl bg-gray-400 animate-pulse my-2 m-auto"></td>
                    <td className="w-[10%] h-6 px-6 pb-0.5 text-lg md:text-xl bg-gray-400 animate-pulse my-2 m-auto"></td>
                    <td className="w-[10%] h-6 px-6 pb-0.5 text-lg md:text-xl bg-gray-400 animate-pulse my-2 m-auto"></td>
                  </tr>
                  <br />
                  <tr>
                    <td className="w-[10%] h-6 px-6 pb-0.5 text-lg md:text-xl bg-gray-400 animate-pulse my-2 m-auto"></td>
                    <td className="w-[10%] h-6 px-6 pb-0.5 text-lg md:text-xl bg-gray-400 animate-pulse my-2 m-auto"></td>
                    <td className="w-[10%] h-6 px-6 pb-0.5 text-lg md:text-xl bg-gray-400 animate-pulse my-2 m-auto"></td>
                  </tr>
                </table>
              </div>
              <div className="w-full md:w-[40%] h-48 rounded-l-md bg-white m-auto py-5 drop-shadow-xl animate-pulse mt-5">
                <h1 className="px-6 pb-0.5 text-lg md:text-xl bg-gray-400 animate-pulse w-full md:w-[40%] h-4 my-2 m-auto"></h1>
                <div className='w-full h-[2px] bg-[#FFD500]'></div>
                <table className='w-full border-collapse'>
                  <tr>
                    <th className='text-center p-2 border-r-2 border-b-2 boder-[#ddd]'>Player</th>
                    <th className='text-center p-2 border-r-2 border-b-2 boder-[#ddd]'>Score</th>
                    <th className='text-center p-2 border-r-2 border-b-2 boder-[#ddd]'>Foul</th>
                  </tr>
                  <tr>
                    <td className="w-[10%] h-6 px-6 pb-0.5 text-lg md:text-xl bg-gray-400 animate-pulse my-2 m-auto"></td>
                    <td className="w-[10%] h-6 px-6 pb-0.5 text-lg md:text-xl bg-gray-400 animate-pulse my-2 m-auto"></td>
                    <td className="w-[10%] h-6 px-6 pb-0.5 text-lg md:text-xl bg-gray-400 animate-pulse my-2 m-auto"></td>
                  </tr>
                  <br />
                  <tr>
                    <td className="w-[10%] h-6 px-6 pb-0.5 text-lg md:text-xl bg-gray-400 animate-pulse my-2 m-auto"></td>
                    <td className="w-[10%] h-6 px-6 pb-0.5 text-lg md:text-xl bg-gray-400 animate-pulse my-2 m-auto"></td>
                    <td className="w-[10%] h-6 px-6 pb-0.5 text-lg md:text-xl bg-gray-400 animate-pulse my-2 m-auto"></td>
                  </tr>
                </table>
              </div>
            </div >
          )

        }

      </div >
    </div >

  );
};

export default MatchDetailLoadingCard;
import React, { useState } from "react";
import FinishedSection from "../containers/FinishedSection";

import LiveSection from "../containers/LiveSection";
import UpcomingSection from "../containers/UpcomingSection";
import { MATCH_STATUS } from "../extras/constants";
import CategoryCard from "../components/CategoryCard";
import VenueCard from "../containers/VenueCard";

function HomePage({ apiResponse, isLoading }) {
  const [venueType, setVenueType] = useState("Indoor");
  const dummyData = {
    outdoor: [
      " Basketball Courts",
      "Football Fields",
      "Tennis",
      "Volleyball",
      "Fitness Zone",
    ],
    indoor: [
      "Private Training slot",
      "Yoga Session",
      "Dance Class",
      "Personal Training",
      "Art Exhibition",
    ]
  }

  return (
    <div className="h-screen w-full">
      <div className="grid grid-cols-1 md:grid-cols-2 mt-10 md:h-1/3 h-1/2 md:gap-10 gap-4 w-11/12 mx-auto">
        <CategoryCard category={"Indoor"} onClick={setVenueType} venueType={venueType} />
        <CategoryCard category={"Outdoor"} onClick={setVenueType} venueType={venueType} />
      </div>
      {
        venueType === "Indoor" ? (
          <div className="mt-10 h-screen md:h-2/3">
            <h1 className="text-3xl font-bold text-center">Indoor Venues</h1>
            <div className="grid grid-cols-1 md:grid-cols-5 h-full pb-10 md:pb-0 md:h-2/5 mt-10 w-10/12 mx-auto gap-5">
              {
                dummyData.indoor.map((item, index) => (
                  <VenueCard key={index} venue={item} />
                ))
              }
            </div>
          </div>) : (
          <div className="mt-10 h-full md:h-2/3">
            <h1 className="text-3xl font-bold text-center">Outdoor Venues</h1>
            <div className="grid grid-cols-1 md:grid-cols-5 h-full pb-10 md:pb-0 md:h-2/5 mt-10 w-10/12 mx-auto gap-5">
              {
                dummyData.outdoor.map((item, index) => (
                  <VenueCard key={index} venue={item} />
                ))
              }
            </div>
          </div>
        )
      }
    </div>
  );
}

export default HomePage;

import React from "react";

function ProfileSingleCard({ label, value }) {
  return (
    <div className="rounded-md p-5 w-[31%] grow drop-shadow-xl bg-white ">
      <div className="flex flex-col items-center">
        <span className="text-[12px] text-center font-medium">{label}</span>
        <h1 className="text-2xl font-semibold">{value}</h1>
      </div>
    </div>
  );
}

export default ProfileSingleCard;

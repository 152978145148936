import React, { useState } from "react";
import Header from "../components/Header";

import { connect } from "react-redux";
import Organized from "../components/Organized";
import Participated from "../components/Participated";
import { LOGIN_MODAL, MATCH_TYPE } from "../extras/constants";
import { showModal } from "../redux/actions/modal";

const MyMatches = ({ auth, showModal }) => {
  const [active, setActive] = useState(MATCH_TYPE.ORGANIZED);

  return (
    <div>
      <Header title={"My Games"} />
      <div className="rounded-xl  m-auto mb-7 px-6 py-5 drop-shadow-2xl bg-white">
        {auth.isLoggedIn ? (
          <>
            <div className="flex justify-between">
              <button
                onClick={() => setActive(MATCH_TYPE.ORGANIZED)}
                className={`px-2 border-b-4 ${
                  active === MATCH_TYPE.ORGANIZED
                    ? " border-app-secondary"
                    : " border-white"
                } font-bold text-center w-full `}
              >
                ORGANIZED
              </button>

              <button
                onClick={() => setActive(MATCH_TYPE.PARTICIPATED)}
                className={`px-2 border-b-4  ${
                  active === MATCH_TYPE.PARTICIPATED
                    ? "border-b-2 border-app-secondary"
                    : " border-white "
                } font-bold text-center w-full `}
              >
                PARTICIPATED
              </button>
            </div>
            {active === MATCH_TYPE.ORGANIZED ? <Organized /> : <Participated />}
          </>
        ) : (
          <>
            <p className="text-center mb-8">
              Please Login before creating a New Match
            </p>
            <button
              onClick={() =>
                showModal({
                  modalType: LOGIN_MODAL,
                })
              }
              className="m-auto block py-2 px-4 font-medium rounded bg-app-secondary text-white"
            >
              Log In
            </button>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showModal: (kind) => dispatch(showModal(kind)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyMatches);

import { Hidden } from "@mui/material";
import React from "react";
import { Route, Routes } from "react-router";
import AboutUsSection from "../../containers/AboutUsSection";
import AddCoachDetails from "../../containers/AddCoachDetails";
import AddPlayerDetails from "../../containers/AddPlayerDetails";
import ContactUsSection from "../../containers/ContactUsSection";
import EditCard from "../../containers/EditCard";
import FinishedSection from "../../containers/FinishedSection";
import Footer from "../../containers/Footer";
import LiveSection from "../../containers/LiveSection";
import MyMatches from "../../containers/MyMatches";
import Navbar from "../../containers/Navbar";
import NewMatchDetail from "../../containers/NewMatchDetail";

import MatchDetailSection from "../../containers/MatchDetailSection";
import MatchType from "../../containers/MatchType";
import ProfileSection from "../../containers/ProfileSection";
import SelectPlayer from "../../containers/SelectPlayer";
import UpdateProfile from "../../containers/UpdateProfile";
import HomePage from "../HomePage";
import VenuePage from "../VenuePage";

const DefaultLayout = () => {
  return (
    <div className="layout">
      <div className="top-0 z-50 sticky">
        <Navbar />
      </div>
      <Routes>
        <Route path="/" element={<HomePage />} />
      </Routes>
      <Routes>
        <Route path="/venue/:venueName" element={<VenuePage />} />
      </Routes>
      <div className="p-2 flex justify-center bg-white shadow">
        <Footer />
      </div>
    </div>
  )
}


export default DefaultLayout;

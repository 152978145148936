import React from "react";
import Header from "../components/Header";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import { BASE_URL } from "../extras/constants";
import axiosInstance from "../axiosInstance";
import SubmitBtn from "../components/formik components/SubmitBtn";
import { useQueryClient } from "react-query";
import Input from "../components/formik components/Input";
import { Link } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";

const CoachSchema = Yup.object().shape({
  coach: Yup.string().required("Coach is Required"),
});

const AddCoachDetails = () => {
  const { matchId, team, teamName } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { teamDetail } = location.state;
  console.log("teamDetail", teamDetail);
  const initialValues = {
    coach: teamDetail?.coach || "",
    assistant: teamDetail?.assistant || "",
  };

  const handleSubmit = (values, setSubmitting) => {
    console.log("values", values);
    const data = {
      matchId: matchId,
      coach: values.coach,
      assistant: values.assistant,
      team: team,
    };

    axiosInstance
      .post(`${BASE_URL}/match/addCoaches`, data)
      .then((response) => {
        setSubmitting(false);
        queryClient.invalidateQueries(["getMatch", matchId]);
        console.log(response);
        toast.success("Coach added successfully");
        navigate(`/mymatches/edit/${matchId}/${team}`);
      })
      .catch((error) => {
        setSubmitting(false);

        console.log(error);
      });
  };

  return (
    <div>
      <Header title={"Add Detail"} />
      <div className="rounded-xl m-auto mb-7 px-8 py-8 drop-shadow-xl min-h-card bg-white">
        <div className="flex items-center pt-4 border-b-2  border-b-secondary -mt-4">
          <Link
            to={`/mymatches/edit/${matchId}/${team}`}
            className="font-semibold px-4 rounded-full"
          >
            <ArrowBack />
          </Link>
          <h1 className="font-semibold text-center text-xl w-[80%]">
            Team {team} : {teamName}
          </h1>
        </div>
        <h3 className="font-bold text-center text-md">Coach Details</h3>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) =>
            handleSubmit(values, setSubmitting)
          }
          validationSchema={CoachSchema}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
            touched,
            isSubmitting,
          }) => {
            return (
              <Form>
                <div className="relative flex items-center mt-5">
                  <Input
                    id={"coach"}
                    name={"coach"}
                    type={"text"}
                    placeholder={"Coach Name"}
                  />
                </div>
                <div className="relative flex items-center mt-5">
                  <Input
                    id={"assistant"}
                    name={"assistant"}
                    type={"text"}
                    placeholder={"Asistant Coach (Optional)"}
                  />
                </div>
                <SubmitBtn
                  text={"Save"}
                  containerClasses="mt-8 "
                  classes={"btn-primary"}
                  isSubmitting={isSubmitting}
                />
                <button
                  type="button"
                  className="btn-secondary my-2"
                  onClick={() => navigate(-1)}
                >
                  Back
                </button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddCoachDetails;

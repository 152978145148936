import axios from "axios";
import axiosInstance from "../../axiosInstance";
import {
  BASE_URL,
  ERROR,
  INFO,
  LOGIN_MODAL,
  SUCCESS,
  VERIFY_OTP_MODAL,
} from "../../extras/constants";
import { getTokens, removeTokens } from "../../extras/utils";
import { hideModal, showModal } from "./modal";
import { addToast, emptyToasts } from "./toasts";
import {
  GET_CURRENT_MATCH,
  GET_CURRENT_USER,
  GET_PLAYER_DETAIL,
  LOGIN_USER,
  LOGOUT_USER,
} from "./types";

export const signupUser = (signupData, setLoading) => {
  return function (dispatch) {
    axios
      .post(`${BASE_URL}/player/signup`, signupData)
      .then((response) => {
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Verification code Sent on mobile!",
          })
        );
        setLoading(false);
        dispatch(hideModal());
        dispatch(
          showModal({
            modalType: VERIFY_OTP_MODAL,
            modalProps: { mobileNumber: signupData.mobileNumber },
          })
        );
      })

      .catch(({ response }) => {
        setLoading(false);
        try {
          switch (response.data.message) {
            case "Player exists":
              //   add a toast
              dispatch(
                addToast({
                  kind: INFO,
                  msg: "Player exists! Login.",
                })
              );
              dispatch(hideModal());
              dispatch(
                showModal({
                  modalType: LOGIN_MODAL,
                })
              );
              break;
            case "Email Already registered, otp sent again":
              //   add a toast
              dispatch(
                addToast({
                  kind: SUCCESS,
                  msg: "Already Registered! OTP Sent Again.",
                })
              );
              dispatch(hideModal());
              // dispatch(
              //   showModal({
              //     modalType: VERIFICATION_MODAL,
              //     modalProps: signupData,
              //   })
              // );
              break;
            default:
              // server error
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Oops, something went wrong",
                })
              );
              break;
          }
        } catch (e) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Couldn't reach the server",
            })
          );
        }
      });
  };
};

export const completeLogin = (token) => {
  return function (dispatch) {
    localStorage.setItem("accessToken", token);
    dispatch({
      type: LOGIN_USER,
      payload: token,
    });
    dispatch(hideModal());
    dispatch(
      addToast({
        kind: SUCCESS,
        msg: "Logged in Successfully!",
      })
    );
    dispatch(getCurrentUser());
  };
};

export const loginUser = (loginData, setLoading) => {
  return function (dispatch) {
    console.log("login DAta", loginData);
    setLoading(true);
    axios
      .post(`${BASE_URL}/player/login`, loginData)
      .then((response) => {
        if (response.data.message) {
          setLoading(false);
          dispatch(hideModal());

          dispatch(
            addToast({
              kind: SUCCESS,
              msg: response.data.message,
            })
          );
          dispatch(
            showModal({
              modalType: VERIFY_OTP_MODAL,
              modalProps: { ...loginData },
            })
          );
        } else
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Invalid ID or Password!",
            })
          );
      })
      .catch(({ response }) => {
        setLoading(false);

        try {
          switch (response.status) {
            case 400:
              //   add a toast
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Something went wrong try again",
                })
              );
              break;
            case 402:
              //   add a toast
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Player Not found! Plese Create Account",
                })
              );
              break;
            default:
              // server error
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Oops, something went wrong",
                })
              );
              break;
          }
        } catch (e) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Couldn't reach the server",
            })
          );
        }
      });
  };
};

export const logoutUser = () => {
  return function (dispatch) {
    // remove stored tokens
    removeTokens();

    // LOGOUT_USER
    dispatch({
      type: LOGOUT_USER,
    });

    // remove all values
    dispatch(emptyToasts());

    // add Toast
    dispatch(
      addToast({
        kind: SUCCESS,
        msg: "You have been logged out!",
      })
    );
  };
};

export const getCurrentUser = () => {
  return function (dispatch) {
    axiosInstance.get(`player/getProfileByToken`).then((response) => {
      let data = response.data.player;
      dispatch({
        type: GET_CURRENT_USER,
        payload: data,
      });
    });
    // dispatch current_user data
  };
};

export const setCurrentMatch = (match) => {
  return function (dispatch) {
    dispatch({
      type: GET_CURRENT_MATCH,
      payload: match,
    });
  };
};

export const getCurrentMatch = (matchId, setIsLoading) => {
  return function (dispatch) {
    console.log("matchId", matchId);
    axios
      .get(`${BASE_URL}/match/getMatchDetails?_id=${matchId}`, {
        headers: {
          Authorization: `Bearer ${getTokens().accessToken}`,
        },
      })
      .then((response) => {
        console.log(response.data.match, "getCurrentMatch");
        dispatch(setCurrentMatch(response.data.match));
        setIsLoading(false);
      })
      .catch((res) => {
        console.log(res, matchId, "errGetCurrentMatch");
      });
  };
};

export const setPlayerDetail = (player) => {
  return function (dispatch) {
    dispatch({
      type: GET_PLAYER_DETAIL,
      payload: player,
    });
  };
};

export const deletePlayer = (
  playerId,
  matchId,
  team,
  setLoading,
  hideModal,
  queryClient
) => {
  return function (dispatch) {
    setLoading(true);
    axiosInstance
      .post(`/match/removePlayer`, {
        playerId,
        matchId,
        team,
        isCaptain: false,
      })
      .then((response) => {
        console.log(response.data, "deletePlayer");
        hideModal();
        queryClient.invalidateQueries("getMatch");
        dispatch(getCurrentMatch(matchId, setLoading));
      });
  };
};

export const getPlayerDetail = (phoneNumber, setLoading) => {
  return function (dispatch) {
    console.log("phoneNumber", phoneNumber);
    axios
      .get(
        `${BASE_URL}/match/getPlayerByPhoneNumber?mobileNumber=${phoneNumber}`,
        {
          headers: {
            Authorization: `Bearer ${getTokens().accessToken}`,
          },
        }
      )
      .then((response) => {
        dispatch(setPlayerDetail(response.data.playerDetails));
        dispatch(hideModal());
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        const {
          response: {
            data: { message },
          },
        } = err;
        // console.log(err);
        dispatch(
          addToast({
            kind: ERROR,
            msg: message,
          })
        );
      });
  };
};

import React from 'react';
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";


const MyMatchesGameCardLoading = () => {
    return (
        <div className="rounded-xl w-full bg-[#FFD500] m-auto mb-7 py-5 drop-shadow-xl animate-pulse mt-5"
        >
            <div className="flex justify-around">
                <div className="px-6 pb-0.5 text-lg md:text-xl bg-gray-700 animate-pulse w-[20%]  m-auto h-2"></div>
                <SportsSoccerIcon />
                <div className="px-6 pb-0.5 text-lg md:text-xl bg-gray-700 animate-pulse w-[20%]  m-auto h-2"></div>
            </div>
            <div className="bg-black h-[1px] my-2"></div>

            <div className="flex px-5 justify-between pt-2">
                <div className="flex">
                    <CalendarMonthIcon />
                    <div className="px-6 pb-0.5 text-lg md:text-xl bg-gray-700 animate-pulse w-[20%]  m-auto h-2"></div>
                </div>
                <div className="flex">
                    <AccessTimeIcon />
                    <div className="px-6 pb-0.5 text-lg md:text-xl bg-gray-700 animate-pulse w-[20%]  m-auto h-2"></div>
                </div>
            </div>

            <div className="flex w-fit px-5 pt-3">
                <LocationOnIcon />
                <div className="px-6 block pb-0.5 text-lg md:text-xl bg-gray-700 animate-pulse w-[20%]  m-auto h-2"></div>
            </div>

            <div className="px-6">
                <button
                    className="appearance-none rounded-full  block w-full mt-4 -mb-2 bg-black border-0 py-2 focus:outline-none text-sm font-semibold text-white"
                >
                    START GAME
                </button>
                <button
                    className="text-center appearance-none rounded-full  block w-full mt-4 -mb-2 bg-black border-0 py-2 focus:outline-none text-sm font-semibold text-white"
                >
                    EDIT DETAILS
                </button>
            </div>
        </div>
    );
};

export default MyMatchesGameCardLoading;
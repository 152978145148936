import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import Header from "../components/Header";

import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import { ToggleOffRounded, ToggleOnRounded } from "@mui/icons-material";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../axiosInstance";
import { ERROR, LOGIN_MODAL, SUCCESS } from "../extras/constants";
import { showModal } from "../redux/actions/modal";
import { addToast } from "../redux/actions/toasts";

const NewGameSchema = Yup.object().shape({
  teamAName: Yup.string().required("Required"),
  teamBName: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  venue: Yup.string().required("Required"),
  floorReferee1: Yup.string().required("Required"),
  tableReferee: Yup.string().required("Required"),
  dateAndTime: Yup.string().required("Required"),
  matchType: Yup.string().required("Required"),
  youtubeLink: Yup.string().notRequired(),
});

const NewMatchDetailSection = ({ edit }) => {
  const navigate = useNavigate();
  const param = useParams();
  const { state } = useLocation();

  const initialValues = {
    teamAName: "" || state?.teamA?.name,
    teamBName: "" || state?.teamB?.name,
    tableReferee: "" || state?.tableReferee,
    floorReferee1: "" || state?.floorReferee1,
    floorReferee2: "" || state?.floorReferee2,
    floorReferee3: "" || state?.floorReferee3,
    venue: "" || state?.venue,
    city: "" || state?.city,
    dateAndTime: "" || state?.dateAndTime?.substring(0, 16),
    // time: '' || state?.dateAndTime?.substring(0, 16),
    threeByThree: param.matchtype === "3" ? true : false,
    youtubeLink: "" || state?.youtubeLink,
    matchType: "",
  };
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const queryClient = useQueryClient();

  const handleSubmit = (values) => {
    console.log("submitting");
    if (!edit) {
      values.threeByThree = param.matchtype === "3" ? true : false;
      axiosInstance
        .post("/match/create", values)
        .then((res) => {
          queryClient.invalidateQueries("matchesList");
          dispatch(
            addToast({
              kind: SUCCESS,
              msg: "Match Created Successfully!",
            })
          );

          navigate(`/mymatches/edit/${res.data.result._id}/A`);
        })
        .catch((err) => {
          console.log(err);
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Unable Create Match!",
            })
          );
        });
    } else {
      console.log(values);
      values.threeByThree = param.matchtype === "3" ? true : false;
      axiosInstance
        .patch(`/match/update/${param.id}`, values)
        .then((res) => {
          queryClient.invalidateQueries("matchesList");
          dispatch(
            addToast({
              kind: SUCCESS,
              msg: "Match Edited Successfully!",
            })
          );

          navigate(`/mymatches/edit/${param.id}/A`);
        })
        .catch((err) => {
          console.log(err);
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Unable to Edit Match!",
            })
          );
        });
    }
  };

  const [embedLink, setEmbedLink] = useState("");

  const validateYouTubeUrl = (url) => {
    let error;
    console.log(url);
    if (url) {
      var regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
      var match = url?.match(regExp);
      if (match && match[2].length === 11) {
        // Do anything for being valid
        // if need to change the url to embed url then use below line
        // $('#videoObject').attr(
        //   'src',
        //   'https://www.youtube.com/embed/' +
        //     match[2] +
        //     '?autoplay=1&enablejsapi=1'
        // );
        setEmbedLink(
          "https://www.youtube.com/embed/" +
            match[2] +
            "?autoplay=1&enablejsapi=1"
        );
      } else {
        console.log("yt not valid");
        error = "Invalid YT Link";
        setEmbedLink("");
        return error;
        // Do anything for not being valid
      }
    }
  };

  return (
    <div>
      <Header title={`${edit ? "Edit" : "Create"} Match`} />
      <div className="rounded-xl m-auto mb-7 px-8 py-5 drop-shadow-2xl z-10 bg-white min-h-card">
        <h1 className="font-bold text-center">
          {edit ? "Edit" : "New"} Match Detail
        </h1>

        {auth.accessToken ? (
          <>
            <div className={`justify-center ${edit ? "hidden" : "flex"}`}>
              <h2 className="font-semibold text-center text-sm">
                This is {param.matchtype}
                <span>
                  <CloseIcon fontSize="15px" />
                </span>
                {param.matchtype} Match (toggle to change)
              </h2>
              {param.matchtype === "3" ? (
                <ToggleOffRounded
                  className="cursor-pointer ml-1"
                  onClick={() => navigate("/mymatches/newmatch/5")}
                />
              ) : (
                <ToggleOnRounded
                  className="cursor-pointer ml-1"
                  onClick={() => navigate("/mymatches/newmatch/3")}
                />
              )}
            </div>

            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={NewGameSchema}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
                isSubmitting,
              }) => {
                return (
                  <Form>
                    <input
                      type="text"
                      name="teamAName"
                      value={values.teamAName}
                      placeholder="Enter First Team Name"
                      onChange={handleChange("teamAName")}
                      className="appearance-none mt-5 block w-full border-2 rounded-xl py-2 px-4 leading-tight focus:outline-none text-sm  placeholder:text-black  focus:bg-[#C2E3F4] focus:text-black focus:border-[#C2E3F4]"
                    />
                    {touched.teamAName && errors.teamAName && (
                      <p className="text-red-500 ml-4">{errors.teamAName}</p>
                    )}
                    <input
                      type="text"
                      name="teamBName"
                      value={values.teamBName}
                      placeholder="Enter Second Team Name"
                      onChange={handleChange("teamBName")}
                      className="appearance-none mt-5 block w-full border-2 rounded-xl py-2 px-4 leading-tight focus:outline-none text-sm  placeholder:text-black  focus:bg-[#C2E3F4] focus:text-black focus:border-[#C2E3F4]"
                    />
                    {touched.teamBName && errors.teamBName && (
                      <p className="text-red-500 ml-4">{errors.teamBName}</p>
                    )}
                    <input
                      type="text"
                      name="tableReferee"
                      value={values.tableReferee}
                      placeholder="Table Referee"
                      onChange={handleChange("tableReferee")}
                      className="appearance-none mt-5 block w-full border-2 rounded-xl py-2 px-4 leading-tight focus:outline-none text-sm  placeholder:text-black  focus:bg-[#C2E3F4] focus:text-black focus:border-[#C2E3F4]"
                    />
                    {touched.tableReferee && errors.tableReferee && (
                      <p className="text-red-500 ml-4">{errors.tableReferee}</p>
                    )}
                    <input
                      type="text"
                      name="floorReferee1"
                      value={values.floorReferee1}
                      placeholder="Floor Referee 1"
                      onChange={handleChange("floorReferee1")}
                      className="appearance-none mt-5 block w-full border-2 rounded-xl py-2 px-4 leading-tight focus:outline-none text-sm  placeholder:text-black  focus:bg-[#C2E3F4] focus:text-black focus:border-[#C2E3F4]"
                    />
                    {touched.floorReferee1 && errors.floorReferee1 && (
                      <p className="text-red-500 ml-4">
                        {errors.floorReferee1}
                      </p>
                    )}
                    <input
                      type="text"
                      name="floorReferee2"
                      value={values.floorReferee2}
                      placeholder="Floor Referee 2"
                      onChange={handleChange("floorReferee2")}
                      className="appearance-none mt-5 block w-full border-2 rounded-xl py-2 px-4 leading-tight focus:outline-none text-sm  placeholder:text-black  focus:bg-[#C2E3F4] focus:text-black focus:border-[#C2E3F4]"
                    />
                    <input
                      type="text"
                      name="floorReferee3"
                      value={values.floorReferee3}
                      placeholder="Floor Referee 3"
                      onChange={handleChange("floorReferee3")}
                      className="appearance-none mt-5 block w-full border-2 rounded-xl py-2 px-4 leading-tight focus:outline-none text-sm  placeholder:text-black  focus:bg-[#C2E3F4] focus:text-black focus:border-[#C2E3F4]"
                    />
                    <input
                      type="text"
                      name="venue"
                      value={values.venue}
                      placeholder="Venue"
                      onChange={handleChange("venue")}
                      className="appearance-none mt-5 block w-full border-2 rounded-xl py-2 px-4 leading-tight focus:outline-none text-sm  placeholder:text-black  focus:bg-[#C2E3F4] focus:text-black focus:border-[#C2E3F4]"
                    />
                    {touched.venue && errors.venue && (
                      <p className="text-red-500 ml-4">{errors.venue}</p>
                    )}
                    <input
                      type="text"
                      name="city"
                      value={values.city}
                      placeholder="City"
                      onChange={handleChange("city")}
                      className="appearance-none mt-5 block w-full border-2 rounded-xl py-2 px-4 leading-tight focus:outline-none text-sm  placeholder:text-black  focus:bg-[#C2E3F4] focus:text-black focus:border-[#C2E3F4]"
                    />
                    {touched.city && errors.city && (
                      <p className="text-red-500 ml-4">{errors.city}</p>
                    )}
                    <input
                      type="datetime-local"
                      name="dateAndTime"
                      value={values.dateAndTime}
                      placeholder="Time"
                      onChange={handleChange("dateAndTime")}
                      className="appearance-none mt-5 block w-full border-2 rounded-xl py-2 px-4 leading-tight focus:outline-none text-sm  placeholder:text-black  focus:bg-[#C2E3F4] focus:text-black focus:border-[#C2E3F4]"
                    />
                    {touched.dateAndTime && errors.dateAndTime && (
                      <p className="text-red-500 ml-4">{errors.dateAndTime}</p>
                    )}
                    <select
                      name="matchType"
                      value={values.matchType}
                      onChange={handleChange("matchType")}
                      placeholder="matchType"
                      className="appearance-none bg-white text-black mt-5 block w-full border-2 rounded-xl py-2 px-4 leading-tight focus:outline-none text-sm placeholder:text-black focus:bg-[#C2E3F4] focus:text-black focus:border-[#C2E3F4] "
                    >
                      <option value="" selected hidden className="text-black">
                        Match Type
                      </option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="mixed">Mixed</option>
                    </select>
                    <Field
                      type="text"
                      name="youtubeLink"
                      value={values.youtubeLink}
                      placeholder="YT Link"
                      onChange={handleChange("youtubeLink")}
                      validate={validateYouTubeUrl}
                      className="appearance-none mt-5 block w-full border-2 rounded-xl py-2 px-4 leading-tight focus:outline-none text-sm  placeholder:text-black  focus:bg-[#C2E3F4] focus:text-black focus:border-[#C2E3F4]"
                    />
                    {touched.youtubeLink && errors.youtubeLink && (
                      <p className="text-red-500 ml-4">{errors.youtubeLink}</p>
                    )}
                    {embedLink && (
                      <iframe
                        src={embedLink}
                        title="YT"
                        id="videoObject"
                        type="text/html"
                        frameborder="0"
                        className="h-full w-full mt-5"
                      />
                    )}
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                      className={`appearance-none rounded-xl  block w-full mt-4 -mb-2 bg-black border-0 py-2 focus:outline-none text-sm font-semibold text-app-secondary ${
                        isSubmitting ? "opacity-80 cursor-not-allowed" : ""
                      }`}
                    >
                      {edit ? "Update" : "Next"}
                    </button>
                  </Form>
                );
              }}
            </Formik>
          </>
        ) : (
          <>
            <p className="text-center mb-8">
              Please Login before creating/editing a Match
            </p>
            <button
              onClick={() =>
                showModal({
                  modalType: LOGIN_MODAL,
                })
              }
              className="m-auto block py-2 px-4 font-medium rounded bg-app-secondary text-white"
            >
              Log In
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default NewMatchDetailSection;

import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import Header from "../components/Header";
import { ERROR, MATCH_STATUS, SUCCESS } from "../extras/constants";
import { isInvalidMatchBeforeStart } from "../extras/utils";
import useGetMatch from "../hooks/api/useGetMatch";
import { addToast } from "../redux/actions/toasts";
import EditCardShowPlayers from "./EditMatchBeforeStart/EditCardShowPlayers";

const SelectPlayer = ({ auth }) => {
  const { matchId, team } = useParams();
  const dispatch = useDispatch();
  const [isInValidMatch, setisInValidMatch] = useState(true);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { dataMatch: matchDetail } = useGetMatch({ id: matchId });
  const teamDetail = team === "A" ? matchDetail?.teamA : matchDetail?.teamB;
  const queryClient = useQueryClient();

  useEffect(() => {
    const isValid = isInvalidMatchBeforeStart(matchDetail);
    console.log("invalid Match", isValid);
    setisInValidMatch(isValid);
  }, [matchDetail]);

  const startMatch = () => {
    setIsLoading(true);
    axiosInstance
      .post("/match/start", {
        matchId: matchId,
      })
      .then((res) => {
        setIsLoading(false);
        queryClient.invalidateQueries(["myMatches", auth.accessToken]);
        queryClient.invalidateQueries("matchesList");
        navigate(`/matchDetail/${matchId}/update/score`);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Match Started Successfully!",
          })
        );
      })
      .catch((err) => {
        setIsLoading(false);

        dispatch(
          addToast({
            kind: ERROR,
            msg: "Unable Start Match!",
          })
        );
      });
  };

  console.log(matchDetail)

  return (
    <div>
      <Header title={"My Games"} />

      <div className="rounded-xl m-auto px-6 py-5 shadow-card min-h-card bg-white flex flex-col h-full justify-between ">
        <div className="flex justify-around mb-3">
          <button
            onClick={() => navigate(`/mymatches/selectPlayer/${matchId}/A`)}
            className={`px-2 outline-none w-full border-b-4  ${team === "A" ? " border-app-secondary" : "border-white "
              } font-semibold `}
          >
            {matchDetail?.teamA?.name || "..."}
          </button>

          <button
            onClick={() => navigate(`/mymatches/selectPlayer/${matchId}/B`)}
            className={`px-2 outline-none  w-full border-b-4  ${team === "B" ? " border-app-secondary" : "border-white"
              } font-semibold `}
          >
            {matchDetail?.teamB?.name || "..."}
          </button>
        </div>

        <div>
          <EditCardShowPlayers
            team={team === "A" ? matchDetail?.teamA : matchDetail?.teamB}
            selectPlayer={true}
          />
        </div>

        <button
          className="btn-primary mt-6 "
          disabled={isInValidMatch || isLoading}
          onClick={() => {
            window.scrollTo(0, 0);
            matchDetail?.status === MATCH_STATUS.UPCOMING
              ? startMatch()
              : navigate(`/matchDetail/${matchDetail._id}/update/score`);
          }}
        >
          {matchDetail?.status === MATCH_STATUS.LIVE
            ? "UPDATE GAME"
            : "START GAME"}
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(SelectPlayer);


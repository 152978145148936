import { ArrowBack } from "@mui/icons-material";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../axiosInstance";
import { BASE_URL, ERROR } from "../extras/constants";
import { getCurrentMatch } from "../redux/actions/auth";
import { addToast } from "../redux/actions/toasts";

const AddScoreCard = ({
  matchDetail,
  playerInfo,
  setPlayerInfo,
  team,
  setTeam,
}) => {
  const [selectedAssist, setSelectedAssist] = useState(null);
  const [selectedPoints, setSelectedPoints] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  console.log("match", playerInfo, team, selectedAssist);

  const { teamA, teamB } = matchDetail;
  const handleAddScore = () => {
    setIsLoading(true);

    const data = {
      scoredId: playerInfo,
      assistedId: selectedAssist,
      points: selectedPoints,
      matchId: matchDetail._id,
      team: team === teamA.name ? "a" : team === teamB.name ? "b" : "",
    };
    axiosInstance
      .post(`${BASE_URL}/match/addScore`, data)
      .then((res) => {
        setIsLoading(false);
        queryClient.invalidateQueries("getMatch");

        console.log(res);
        toast.success(
          `Score Updated Successfully for team ${
            team === "a" ? matchDetail.teamA.name : matchDetail.teamB.name
          } `
        );
        navigate(`/matchDetail/${matchDetail._id}/update/score`);
        dispatch(getCurrentMatch(matchDetail._id, setIsLoading));
      })
      .catch((err) => {
        setIsLoading(false);
        queryClient.invalidateQueries("getMatch");
        const {
          response: {
            data: { error },
            status,
          },
        } = err;
        try {
          switch (status) {
            case 400:
              //   add a toast
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: error,
                })
              );
              break;
            case 401:
              //   add a toast

              dispatch(
                addToast({
                  kind: ERROR,
                  msg: error,
                })
              );
              break;
            default:
              toast.error("Error", "Something went wrong");
              break;
          }
        } catch (e) {
          toast.error("Error", "Something went wrong");
        }
      });
  };

  const handleFoul = () => {
    setIsLoading(true);

    const data = {
      foulCommitedById: playerInfo,
      matchId: matchDetail._id,
      team: team === teamA.name ? "a" : team === teamB.name ? "b" : "",
      gameTime: matchDetail.dateAndTime,
    };
    axiosInstance
      .post(`/match/addFoul`, data)
      .then((res) => {
        setIsLoading(false);
        queryClient.invalidateQueries("getMatch");

        console.log(res);
        toast.success(`Foul Updated Successfully for team ${team} `);
        navigate(`/matchDetail/${matchDetail._id}/update/score`);
        setSelectedPoints(null);
      })
      .catch((err) => {
        queryClient.invalidateQueries("getMatch");
        const {
          response: {
            data: { error },
            status,
          },
        } = err;

        setIsLoading(false);

        console.log(err);
        try {
          switch (status) {
            case 400:
              //   add a toast
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: error,
                })
              );
              break;
            case 401:
              //   add a toast
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: error,
                })
              );
              break;
            default:
              toast.error("Something went wrong");
              break;
          }
        } catch (e) {
          toast.error("Something went wrong");
        }
      });
  };

  const handleAddScoreWithoutAssist = () => {
    setIsLoading(true);

    const data = {
      scoredId: playerInfo,
      points: selectedPoints,
      matchId: matchDetail._id,
      team: team === teamA.name ? "a" : team === teamB.name ? "b" : "",
      gameTime: matchDetail.dateAndTime,
    };
    axiosInstance
      .post(`/match/addScoreWithoutAssist`, data)
      .then((res) => {
        setIsLoading(false);
        queryClient.invalidateQueries("getMatch");

        console.log(res);
        toast.success(`Score Updated Successfully for team ${team} `);
        navigate(`/matchDetail/${matchDetail._id}/update/score`);
        setSelectedPoints(null);
      })
      .catch((err) => {
        queryClient.invalidateQueries("getMatch");
        const {
          response: {
            data: { error },
            status,
          },
        } = err;

        setIsLoading(false);

        console.log(err);
        try {
          switch (status) {
            case 400:
              //   add a toast
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: error,
                })
              );
              break;
            case 401:
              //   add a toast
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: error,
                })
              );
              break;
            default:
              toast.error("Something went wrong");
              break;
          }
        } catch (e) {
          toast.error("Something went wrong");
        }
      });
  };
  console.log(playerInfo);

  const ScoringButton = ({ value, label, disabled }) => {
    return (
      <div
        onClick={() => {
          if (disabled) return;
          setSelectedPoints(value);
        }}
        className={`py-6 hover:bg-yellow-300 cursor-pointer duration-75 border w-full flex flex-col items-center justify-center h-full bg-white border-black ${
          disabled ? "text-gray-500 hover:bg-white cursor-not-allowed" : ""
        } ${selectedPoints === value ? "bg-yellow-400 text-white" : ""}`}
      >
        <p className="font-bold text-base">{label}</p>
      </div>
    );
  };

  return (
    <div className="rounded-md drop-shadow-xl bg-white relative">
      <div className="flex items-center pt-4 border-b-2  border-b-secondary">
        <Link
          to={`/matchDetail/${matchDetail?._id}/update`}
          className="font-semibold px-4 rounded-full"
        >
          <ArrowBack />
        </Link>
        <h1 className="font-semibold text-center text-xl w-[80%]">Add Score</h1>
      </div>
      <div className="w-11/12 mx-auto mt-2 mb-2 rounded-xl bg-black text-white py-2 text-center">
        Scoring for{" "}
        {(team === teamA.name &&
          matchDetail?.teamA?.players.map((player, index) => {
            if (player._id._id === playerInfo) {
              return "#" + player._id?.jerseyNumber + " " + player.name;
            }
          })) ||
          (team === teamB.name &&
            matchDetail?.teamB?.players.map((player, index) => {
              if (player._id._id === playerInfo) {
                return "#" + player._id?.jerseyNumber + " " + player.name;
              }
            })) ||
          "None"}
      </div>
      <div className="w-11/12 border border-black mx-auto grid gap-0 grid-cols-4 place-items-center justify-items-center">
        <ScoringButton value={1} label={"+1"} />
        <ScoringButton value={2} label={"+2"} />
        <ScoringButton value={3} label={"+3"} />
        <ScoringButton value={"foul"} label={"Foul +1"} />
        <ScoringButton value={-1} disabled label={"-1"} />
        <ScoringButton value={-2} disabled label={"-2"} />
        <ScoringButton value={-3} disabled label={"-3"} />
        <ScoringButton value={10} disabled label={"Timeout"} />
      </div>
      <div className="w-full flex items-center justify-center">
        <button
          type="submit"
          disabled={isLoading || playerInfo === {} || selectedPoints === null}
          onClick={() => {
            if (selectedPoints === "foul") {
              handleFoul();
            } else {
              handleAddScoreWithoutAssist();
            }
          }}
          className="btn-primary !w-[80%] rounded-full  mt-2 mb-4"
        >
          Submit
        </button>
      </div>
    </div>
  );

  return (
    <div className="rounded-md drop-shadow-xl bg-white relative">
      <div className="flex items-center pt-4 border-b-2  border-b-secondary">
        <Link
          to={`/matchDetail/${matchDetail?._id}/update`}
          className="font-semibold px-4 rounded-full"
        >
          <ArrowBack />
        </Link>
        <h1 className="font-semibold text-center text-xl w-[80%]">Add Score</h1>
      </div>
      <div className="flex items-center justify-center flex-col gap-3 py-4">
        <div className="flex items-center justify-around gap-x-10">
          <h1 className="font-semibold text-xl">Points</h1>
          <div className="flex gap-5">
            <button
              onClick={() => setSelectedPoints(1)}
              className={`font-semibold px-4 rounded-full bg-black text-white hover:bg-[#ffd500] ${
                selectedPoints == 1 ? "bg-[#ffd500]" : "bg-black"
              }`}
            >
              1
            </button>
            <button
              onClick={() => setSelectedPoints(2)}
              className={`font-semibold px-4 rounded-full bg-black text-white hover:bg-[#ffd500] ${
                selectedPoints == 2 ? "bg-[#ffd500]" : "bg-black"
              }`}
            >
              2
            </button>
            <button
              onClick={() => setSelectedPoints(3)}
              className={`font-semibold px-4 rounded-full bg-black text-white hover:bg-[#ffd500] ${
                selectedPoints == 3 ? "bg-[#ffd500]" : "bg-black"
              }`}
            >
              3
            </button>
          </div>
        </div>
        <div className="flex items-center justify-between gap-x-10  w-[80%] ">
          <h1 className="font-semibold text-lg ">Team</h1>
          <select
            value={team}
            onChange={(e) => {
              const value = e.target.value;
              console.log(value);
              setTeam(value);
              value === teamA.name && setPlayerInfo(teamA.players[0]._id);
              value === teamB.name && setPlayerInfo(teamB.players[0]._id);
            }}
            className="px-11 border-2 rounded-full   w-2/3"
          >
            <option value={""}>Select Team</option>

            <option value={teamA.name}>{teamA.name}</option>
            <option value={teamB.name}>{teamB.name}</option>
          </select>
        </div>
        {
          <>
            <div className="flex items-center justify-between gap-x-10   w-[80%] ">
              <h1 className="font-semibold text-lg ">Players</h1>
              <select
                className="px-11 border-2 rounded-full w-2/3"
                value={playerInfo}
                onChange={(e) => {
                  console.log(e.target.value);
                  setPlayerInfo(e.target.value);
                }}
              >
                {team === teamA.name &&
                  matchDetail?.teamA?.players.map(
                    (player, index) =>
                      player.isActive && (
                        <option value={player._id?._id} key={index}>
                          {player.name}
                        </option>
                      )
                  )}
                {team === teamB.name &&
                  matchDetail?.teamB?.players.map(
                    (player, index) =>
                      player.isActive && (
                        <option value={player._id?._id} key={index}>
                          {player.name}
                        </option>
                      )
                  )}
              </select>
            </div>
            <div className="flex items-center justify-between gap-x-14  w-[80%] ">
              <h1 className="font-semibold text-lg">Assist</h1>
              <select
                onChange={(item) => setSelectedAssist(item.target.value)}
                className="px-11 border-2 rounded-full w-2/3"
              >
                <option value={""}>None</option>
                {team === teamA.name &&
                  matchDetail?.teamA?.players.map(
                    (player, index) =>
                      player.isActive &&
                      playerInfo !== player._id?._id && (
                        <option value={player._id?._id} key={index}>
                          {player.name}
                        </option>
                      )
                  )}
                {team === teamB.name &&
                  matchDetail?.teamB?.players.map(
                    (player, index) =>
                      player.isActive &&
                      playerInfo !== player._id?._id && (
                        <option value={player._id?._id} key={index}>
                          {player.name}
                        </option>
                      )
                  )}
              </select>
            </div>
          </>
        }
      </div>
      <div className="w-full flex items-center justify-center">
        <button
          type="submit"
          disabled={isLoading}
          onClick={() =>
            !selectedAssist ? handleAddScoreWithoutAssist() : handleAddScore()
          }
          className="btn-primary !w-[80%] rounded-full  mt-2 mb-4"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default AddScoreCard;

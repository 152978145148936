import { useParams } from "react-router"

export default function VenuePage() {
  const params = useParams()

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const description = " A pulsating symphony of movement and energy, this enigmatic sanctuary reverberates with raw passion and unwavering spirit.Within its hallowed walls, the air crackles with anticipation, as echoes of determination intertwine with the beating heart of competition.The stage is set for a captivating spectacle, where bodies in graceful motion paint a vivid tableau of athleticism.Waves of fervor ripple through the crowd, their collective voices blending into a chorus of unyielding support.A vibrant mosaic of colors adorns the space, embracing the essence of unity amidst diversity.Here, dreams take flight, boundless potential finds its voice, and the human spirit soars on the wings of possibility."


  return (
    <div className="h-screen">
      <div className="w-11/12 h-full flex mt-10 md:mt-14 mx-auto space-x-8">
        <img className="w-1/2 h-1/2 object-cover rounded-lg" src={`https://source.unsplash.com/featured/${params?.venueName}`} />
        <div className="flex flex-col w-1/2">
          <h1 className="text-2xl md:text-4xl font-bold text-left mt-2">{capitalize(params?.venueName).replace("_", " ")}</h1>
          <p className="text-left mt-3 text-lg">
            {description}
          </p>
        </div>
      </div>
    </div>
  )
}

import jwtDecode from "jwt-decode";

export const checkTokens = () => {
  try {
    const refreshToken = localStorage.getItem("refreshToken");
    const accessToken = localStorage.getItem("accessToken");

    if (!refreshToken && !accessToken) {
      return false;
    }

    // first check, if you have a valid access_token
    if (accessToken) {
      // accessToken may be invalid, or expired, or no refreshToken or refreshToken present or refreshToken may be invalid
      try {
        // decode the token
        // invalid or malformed token will throw error
        const atoken = jwtDecode(accessToken);

        let exp = null;

        if (atoken && atoken?.exp) {
          exp = atoken.exp;
        }

        // if no exp date or expired exp date
        if (!exp || exp < new Date().getTime() / 1000) {
          // invalid accessToken
          // now check for refreshToken
          if (refreshToken) {
            const rtoken = jwtDecode(refreshToken);
            let exp = null;

            if (rtoken && rtoken?.exp) {
              exp = rtoken.exp;
            }

            // if no exp date or expired exp date
            if (!exp || exp < new Date().getTime() / 1000) {
              return false;
            }
          } else {
            return false;
          }
        }
      } catch {
        // invalid accessToken
        // now check for refreshToken
        if (refreshToken) {
          const rtoken = jwtDecode(refreshToken);
          let exp = null;

          if (rtoken && rtoken?.exp) {
            exp = rtoken.exp;
          }

          // if no exp date or expired exp date
          if (!exp || exp < new Date().getTime() / 1000) {
            return false;
          }
        } else {
          return false;
        }
      }
    } else {
      // we have refreshToken
      // check if refreshToken exists or not
      const rtoken = jwtDecode(refreshToken);
      let exp = null;

      if (rtoken && rtoken?.exp) {
        exp = rtoken.exp;
      }

      // if no exp date or expired exp date
      if (!exp || exp < new Date().getTime() / 1000) {
        return false;
      }
    }

    // valid token
    return true;
  } catch (e) {
    return false;
  }
};

export const getTokens = () => {
  // check if the user has a valid or a access_token refresh_token
  if (checkTokens()) {
    return {
      accessToken: localStorage.getItem("accessToken"),
      refreshToken: localStorage.getItem("refreshToken"),
    };
  }

  removeTokens();
  return {
    accessToken: null,
    refreshToken: null,
  };
};

export const saveTokens = (accessToken, refreshToken) => {
  localStorage.setItem("accessToken", accessToken);
  localStorage.setItem("refreshToken", refreshToken);
};

// fn to save new access token
export const saveAccessTokens = (accessToken) => {
  localStorage.setItem("accessToken", accessToken);
};

// fn to remove tokens
export const removeTokens = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};

export function dateIsValid(dateStr) {
  if (!dateStr) return false;
  const regex = /^\d{2}\/\d{2}\/\d{4}$/;

  if (dateStr.match(regex) === null) {
    return false;
  }

  const [day, month, year] = dateStr.split("/");

  // 👇️ format Date string as `yyyy-mm-dd`
  const isoFormattedStr = `${year}-${month}-${day}`;

  const date = new Date(isoFormattedStr);

  const timestamp = date.getTime();

  if (typeof timestamp !== "number" || Number.isNaN(timestamp)) {
    return false;
  }

  return date.toISOString().startsWith(isoFormattedStr);
}

export function timeIsValid(time) {
  if (!time) return false;

  const regex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
  return time.match(regex) !== null;
}

export const isInvalidMatchBeforeStart = (match) => {
  if (!match) return true;
  const checkForActivePlayer = (team, minNecessaryPlayers) => {
    let playerCount = 0;
    for (let player of team) {
      if (player?.isActive) playerCount++;
    }
    return playerCount < minNecessaryPlayers;
  };

  if (match?.threeByThree) {
    let incompleteThreeByThree =
      match?.teamA?.players.length < 3 || match?.teamB?.players.length < 3;

    if (incompleteThreeByThree) return true;

    return (
      checkForActivePlayer(match?.teamA?.players, 3) ||
      checkForActivePlayer(match?.teamB?.players, 3)
    );
  } else {
    let incompleteFiveByFive =
      match?.teamA?.players.length < 5 || match?.teamB?.players.length < 5;
    if (incompleteFiveByFive) return true;
    return (
      checkForActivePlayer(match?.teamA?.players, 5) ||
      checkForActivePlayer(match?.teamB?.players, 5)
    );
  }
};

export const camelCaseToTitleCase = (s) => {
  const result = s.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};
// function convertUTCDateToLocalDate(date) {
//   var newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
//   return newDate;
// }

export const formateDate = (date) => {
  if (typeof date === "string") date = new Date(date);
  // date = - userTimezoneOffset;

  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1;
  const year = date.getUTCFullYear();

  return `${day}-${month}-${year}`;
};

export const formateTime = (time) => {
  var isoTime = time.split("T")[1];
  console.log(isoTime);
  var hours = parseInt(isoTime.substring(0, 2), 10),
    minutes = isoTime.substring(3, 5),
    ampm = "am";

  console.log(hours, minutes);
  console.log(`${hours}:${minutes} ${ampm.toUpperCase()}`);
  if (hours === 12) {
    ampm = "pm";
  } else if (hours === 0) {
    hours = 12;
  } else if (hours > 12) {
    hours -= 12;
    ampm = "pm";
  }

  return `${hours}:${minutes} ${ampm.toUpperCase()}`;
};

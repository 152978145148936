import { connect } from "react-redux";
import { hideModal } from "../redux/actions/modal";
import CreateAccountModal from "../components/CreateAccountModal";
import LoginModal from "../components/LoginModal";
import {
  LOGIN_MODAL,
  CREATE_ACCOUNT_MODAL,
  VERIFY_OTP_MODAL,
  PLAYER_EXISTS_MODAL,
  DELETE_CONFIRMATION_MODAL,
  EDIT_PLAYER_MODAL,
} from "../extras/constants";
import useClose from "../hooks/useClose";
import VerifyOtpModal from "../Modals/VerifyOtpModal";
import PlayerExistsModal from "../components/PlayerExistsModal";
import DeletePlayerModal from "../components/DeletePlayerModal";
import EditPlayerModal from "../components/EditPlayerModal";

const Modal = ({ modal, hideModal }) => {
  const ref = useClose(() => hideModal());

  let Component = null;

  switch (modal.modalType) {
    case LOGIN_MODAL:
      Component = LoginModal;
      break;
    case CREATE_ACCOUNT_MODAL:
      Component = CreateAccountModal;
      break;
    case VERIFY_OTP_MODAL:
      Component = VerifyOtpModal;
      break;
    case PLAYER_EXISTS_MODAL:
      Component = PlayerExistsModal;
      break;
    case DELETE_CONFIRMATION_MODAL:
      Component = DeletePlayerModal;
      break;
    case EDIT_PLAYER_MODAL:
      Component = EditPlayerModal;
      break;
    default:
      Component = null;
  }

  return (
    <div className="backdrop fixed w-auto h-full top-0 right-0 bottom-0 left-0 z-50 flex bg-gray-800 bg-opacity-50 justify-center items-center">
      <div
        className="modal flex items-center justify-center bg-klutchh-modal h-screen px-6 md:px-8 py-6 w-screen  sm:inline sm:h-auto sm:w-4/6 md:w-3/6 lg:w-3/6 xl:w-2/6  bg-white p-5 rounded-xl text-black"
        ref={ref}
      >
        {!modal.noHeader && (
          <div className="top flex items-center justify-between mb-4 ">
            <div className="flex flex-row items-center ">
              <h3 className=" font-bold text-xl ">{modal.modalTitle}</h3>
              <p className="text-xs text-gray-500 ml-3">
                {modal.modalSubTitle}
              </p>
            </div>
            <button
              className="block sm:hidden absolute top-5 right-5"
              onClick={() => hideModal()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        )}
        {Component !== null && (
          <Component props={modal.modalProps} hideModal={hideModal} />
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideModal: () => dispatch(hideModal()),
  };
};

export default connect(null, mapDispatchToProps)(Modal);

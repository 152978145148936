import React from "react";

const MatchHeader = ({ title }) => {
  return (
    <div className="px-6 py-2 rounded-full bg-black w-[65%] m-auto mb-5">
      <h1 className="text-white text-center font-bold uppercase ">{title}</h1>
    </div>
  );
};

export default MatchHeader;

import { useState } from "react";
import { toast } from "react-toastify";

import { useQuery, useQueryClient } from "react-query";
import { connect, useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import axiosInstance from "../axiosInstance";
import { BASE_URL, ERROR, SUCCESS } from "../extras/constants";

import img from "../assets/basketball-athlete-1.png";
import AddFoulCard from "../components/AddFoulCard";
import AddScoreCard from "../components/AddScoreCard";
import AddSubsitutionCard from "../components/AddSubsitutionCard";
import AllOptionsCard from "../components/AllOptionsCard";
import MatchDetailLoadingCard from "../components/MatchDetailLoadingCard";
import NewMatchCard from "../components/NewMatchCard";
import TeamDetail from "../components/TeamDetail";
import useGetMatch from "../hooks/api/useGetMatch";
import { addToast } from "../redux/actions/toasts";

import { Dialog, DialogContent, DialogTitle } from "@mui/material";

const MatchDetailSection = ({ auth }) => {
  const location = useLocation();
  const param = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { dataMatch: matchDetail, isLoadingMatch } = useGetMatch({
    id: param.matchId,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [playerInfo, setPlayerInfo] = useState({});
  const [team, setTeam] = useState(null);
  const [totalScore, setTotalScore] = useState({
    teamA: 0,
    teamB: 0,
  });

  const changeQuarter = (value) => {
    setIsLoading(true);
    axiosInstance
      .post(`${BASE_URL}/match/changeQuarter`, {
        matchId: matchDetail?._id,
        quarter: parseInt(value),
      })
      .then((res) => {
        setIsLoading(false);

        queryClient.invalidateQueries("getMatch");
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: `Quarter Changed to Q${value}`,
          })
        );
      })
      .catch((err) => {
        setIsLoading(false);

        const { response } = err;
        console.log(response);
        try {
          switch (response.status) {
            case 400:
              //   add a toast
              toast.error("Error", "Invalid Data");
              break;
            case 401:
              //   add a toast
              toast.error("Error", "Unauthorized");
              break;
            default:
              toast.error("Error", "Something went wrong");
              break;
          }
        } catch (e) {
          toast.error("Error", "Something went wrong");
        }
      });
  };

  const getQuarterScores = async () => {
    const { data } = await axiosInstance.get(
      `/match/getQuarterScore/${param.matchId}`
    );
    const { teamA, teamB } = data;
    let teamAScore = Object.values(teamA);
    teamAScore = teamAScore.reduce((acc, score) => acc + score, 0);
    console.log(teamAScore);
    let teamBScore = Object.values(teamB);
    teamBScore = teamBScore.reduce((acc, score) => acc + score, 0);
    console.log(teamAScore, teamBScore);
    setTotalScore({
      teamA: teamAScore,
      teamB: teamBScore,
    });
    return data;
  };
  const { data: quarterScores, error } = useQuery(
    ["getMatch", "getQuarter", param.matchId],
    getQuarterScores
  );

  const endMatch = () => {
    setIsLoading(true);
    axiosInstance
      .post(`${BASE_URL}/match/finishMatch`, {
        matchId: param.matchId,
      })
      .then((response) => {
        setIsLoading(false);

        console.log("end match", response.data);
        queryClient.invalidateQueries("matchesList");
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Match Ended Successfully!",
          })
        );
        navigate("/mymatches");
      })
      .catch((err) => {
        setIsLoading(false);

        console.log(" err end match", err);
        dispatch(
          addToast({
            kind: ERROR,
            msg: "Unable End Match!",
          })
        );
      });
  };

  const cards = () => {
    if (location.pathname.includes("score")) {
      return (
        <AddScoreCard
          matchDetail={matchDetail}
          playerInfo={playerInfo}
          team={team}
          setTeam={setTeam}
          setPlayerInfo={setPlayerInfo}
        />
      );
    } else if (location.pathname.includes("foul")) {
      return (
        <AddFoulCard
          matchDetail={matchDetail}
          playerInfo={playerInfo}
          team={team}
          setTeam={setTeam}
          setPlayerInfo={setPlayerInfo}
        />
      );
    } else if (location.pathname.includes("subsitution")) {
      return (
        <AddSubsitutionCard
          matchDetail={matchDetail}
          playerInfo={playerInfo}
          team={team}
          setTeam={setTeam}
          setPlayerInfo={setPlayerInfo}
        />
      );
    } else {
      return <AllOptionsCard matchDetail={matchDetail} />;
    }
  };

  const [embedLink, setEmbedLink] = useState("");
  const [open, setOpen] = useState(false);

  const viewVideo = () => {
    if (matchDetail?.youtubeLink && !embedLink) {
      var url = matchDetail?.youtubeLink;
      var regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
      var match = url?.match(regExp);
      if (match && match[2].length === 11) {
        // Do anything for being valid
        // if need to change the url to embed url then use below line
        // $('#videoObject').attr(
        //   'src',
        //   'https://www.youtube.com/embed/' +
        //     match[2] +
        //     '?autoplay=1&enablejsapi=1'
        // );
        setEmbedLink(
          "https://www.youtube.com/embed/" +
            match[2] +
            "?autoplay=1&enablejsapi=1"
        );
        setOpen(true);
      }
    } else if (embedLink) {
      setOpen(true);
    }
  };

  // console.log(quarterScores?.teamA[1], "quarter score");
  return (
    <>
      <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
        <DialogTitle className="text-center">YT Stream</DialogTitle>
        <DialogContent>
          {embedLink && (
            <iframe
              allowFullScreen
              src={embedLink}
              title="YT"
              id="videoObject"
              type="text/html"
              frameborder="0"
              className="h-80 w-full"
            />
          )}
        </DialogContent>
      </Dialog>
      {isLoadingMatch ? (
        <MatchDetailLoadingCard />
      ) : error ? (
        <div>Something Went Wrong</div>
      ) : (
        <div className="2xl:w-[70%] m-auto px-3 md:px-5 my-10">
          <div className="w-full rounded-md drop-shadow-lg bg-white">
            <div className="flex justify-between px-6 py-5 items-center">
              <h1 className="font-bold text-2xl">
                Scores of {matchDetail?.teamA?.name} Vs{" "}
                {matchDetail?.teamB?.name}
              </h1>
              {matchDetail?.youtubeLink && (
                <button
                  className="py-1 px-4 font-medium text-sm text-white bg-black rounded-full hover:text-app-secondary transition duration-300"
                  onClick={viewVideo}
                >
                  VIEW VIDEO
                </button>
              )}
            </div>
            <div className="w-full rounded-md drop-shadow-xl bg-[#EDEDED] pb-2">
              {location.pathname.includes("update") && (
                <div className="flex justify-end">
                  {!matchDetail?.threeByThree && (
                    <>
                      <button
                        disabled={isLoading || matchDetail?.currentQuarter == 1}
                        onClick={() =>
                          changeQuarter(
                            parseInt(
                              matchDetail?.currentQuarter === null
                                ? 0
                                : matchDetail?.currentQuarter
                            ) - 1
                          )
                        }
                        className={`py-1 mt-2 mr-2 px-4 font-medium text-sm text-black bg-[#fff] rounded-full ${
                          matchDetail?.currentQuarter == 1
                            ? "cursor-not-allowed"
                            : "hover:text-app-secondary"
                        }  transition duration-300`}
                      >
                        PREVIOUS QUARTER
                      </button>
                      <button
                        disabled={isLoading || matchDetail?.currentQuarter == 4}
                        onClick={() =>
                          changeQuarter(
                            parseInt(
                              matchDetail?.currentQuarter === null
                                ? 0
                                : matchDetail?.currentQuarter
                            ) + 1
                          )
                        }
                        className={`py-1 mt-2 mr-2 px-4 font-medium text-sm text-black bg-[#fff] rounded-full ${
                          matchDetail?.currentQuarter == 4
                            ? "cursor-not-allowed"
                            : "hover:text-app-secondary"
                        }  transition duration-300`}
                      >
                        NEXT QUARTER
                      </button>
                    </>
                  )}
                  <button
                    disabled={isLoading}
                    className="py-1  mt-2 mr-2 px-4 font-medium text-sm text-white bg-[#E40000] rounded-full hover:text-app-secondary transition duration-300"
                    onClick={endMatch}
                  >
                    END MATCH
                  </button>
                </div>
              )}
              <div className="w-full max-h-44 rounded-md drop-shadow-xl flex flex-col md:flex-row my-5">
                <div
                  className={`${
                    matchDetail?.threeByThree ? "w-1/2" : "w-full"
                  } flex items-center justify-center rounded-l-md drop-shadow-xl bg-[#FFD500] py-5`}
                >
                  <div className="flex items-end gap-5 lg:gap-x-14">
                    <div>
                      <h1 className="my-2 font-semibold">
                        {matchDetail?.teamA?.name}
                      </h1>
                      <h1 className="my-1 font-semibold">
                        {matchDetail?.teamB?.name}
                      </h1>
                    </div>
                    <div className=" flex lg:gap-14 gap-4 items-center">
                      {[1, 2, 3, 4, 5].map((item) => {
                        if (matchDetail?.threeByThree && item > 1) return;
                        return (
                          <div
                            className="flex flex-col items-center"
                            key={item}
                          >
                            <h1
                              className={`my-2 font-semibold px-2 ${
                                matchDetail?.currentQuarter === item
                                  ? "bg-white"
                                  : " "
                              }`}
                            >
                              {item === 5 || matchDetail?.threeByThree
                                ? "TOT"
                                : "Q" + item}
                            </h1>
                            <h1 className="my-1 font-semibold">
                              {item === 5
                                ? totalScore.teamA
                                : quarterScores?.teamA[item] ?? 0}
                            </h1>
                            <h1 className="my-1 font-semibold">
                              {item === 5
                                ? totalScore.teamB
                                : quarterScores?.teamB[item] ?? 0}
                            </h1>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="w-full overflow-y-scroll rounded-md drop-shadow-xl bg-white ">
                  <table className="w-full border-collapse">
                    <tr>
                      <th className="text-center p-2 border-r-2 border-b-2 boder-[#ddd]">
                        Type
                      </th>
                      <th className="text-center p-2 border-r-2 border-b-2 boder-[#ddd]">
                        Event
                      </th>
                    </tr>
                    {matchDetail?.events.length === 0 ? (
                      <tr>
                        <td className="text-center p-2 border-r-2 border-[#ddd] ">
                          -
                        </td>
                        <td>
                          <h1 className="text-center p-2 border-r-2 border-[#ddd] ">
                            No Scores Added Yet. Please Wait.
                          </h1>
                        </td>
                      </tr>
                    ) : (
                      matchDetail?.events
                        .slice(0)
                        .reverse()
                        .map((event) => (
                          <tr>
                            {event.eventType === "score" && (
                              <>
                                <td className="text-center p-2 border-r-2 border-[#ddd] ">
                                  Score
                                </td>
                                <td className="text-center p-2 border-r-2 border-[#ddd] ">
                                  <p className="font-medium text-[#000000B8] w-full text-left">
                                    '{event.scoredBy.name}' from team '
                                    {event.team === "a"
                                      ? matchDetail?.teamA.name
                                      : matchDetail?.teamB.name}
                                    ' scored {event.points} Point(s) in Q
                                    {event.quarter}{" "}
                                    {event.assistedBy &&
                                      " and was assisted by '" +
                                        event.assistedBy.name +
                                        "'"}
                                  </p>
                                </td>
                              </>
                            )}

                            {event.eventType === "substitute" && (
                              <>
                                <td className="text-center p-2 border-r-2 border-[#ddd] ">
                                  Substitute
                                </td>
                                <td className="text-center p-2 border-r-2 border-[#ddd] ">
                                  <p className="font-medium text-[#000000B8] w-full text-left">
                                    '{event.playerIn.name}' from team '
                                    {event.team === "a"
                                      ? matchDetail?.teamA.name
                                      : matchDetail?.teamB.name}
                                    ' substituted by {event.playerOut.name} in Q
                                    {event.quarter}{" "}
                                  </p>
                                </td>
                              </>
                            )}
                            {event.eventType === "foul" && (
                              <>
                                <td className="text-center p-2 border-r-2 border-[#ddd]">
                                  Fouls
                                </td>
                                <td className="text-center p-2 border-r-2 border-[#ddd]">
                                  {event.foulCommittedOn ? (
                                    <p className="font-medium text-[#000000B8] w-full text-left">
                                      '{event.foulCommittedBy.name}' from team '
                                      {event.team === "a"
                                        ? matchDetail?.teamA.name
                                        : matchDetail?.teamB.name}
                                      ' commited a foul on '
                                      {event.foulCommittedOn.name}'' from team '
                                      {event.team === "a"
                                        ? matchDetail?.teamB.name
                                        : matchDetail?.teamA.name}
                                      ' in Q{event.quarter}{" "}
                                    </p>
                                  ) : (
                                    <p className="font-medium text-[#000000B8] w-full text-left">
                                      '{event.foulCommittedBy.name}' from team '
                                      {event.team === "a"
                                        ? matchDetail?.teamA.name
                                        : matchDetail?.teamB.name}
                                      ' commited a foul in Q{event.quarter}{" "}
                                    </p>
                                  )}
                                </td>
                              </>
                            )}
                            {/* <p>{event}</p> */}
                          </tr>
                        ))
                    )}
                  </table>
                </div>
              </div>
              {location.pathname.includes("update") ? (
                <div className="flex flex-col lg:flex-row justify-around">
                  <div className="w-full md:w-[80%] m-auto mt-5 lg:w-[30%]">
                    <TeamDetail
                      teamDetail={matchDetail?.teamA}
                      team={"a"}
                      playerInfo={playerInfo}
                      setPlayerInfo={setPlayerInfo}
                      setTeam={setTeam}
                      viewOnly={true}
                    />
                  </div>
                  <div className="w-full md:w-[80%] m-auto mt-5 lg:w-[30%]">
                    {cards()}
                  </div>
                  <div className="w-full md:w-[80%] m-auto mt-5 lg:w-[30%]">
                    <TeamDetail
                      teamDetail={matchDetail?.teamB}
                      setPlayerInfo={setPlayerInfo}
                      playerInfo={playerInfo}
                      setTeam={setTeam}
                      teamInfo={team}
                      team={"b"}
                      viewOnly={true}
                    />
                  </div>
                </div>
              ) : (
                <div className="flex md:flex-row flex-col justify-around">
                  <div className="mb-5 md:w-[45%]">
                    <TeamDetail
                      teamDetail={matchDetail?.teamA}
                      viewOnly={true}
                      team={"a"}
                    />
                  </div>
                  <div className="mb-5 md:w-[45%]">
                    <TeamDetail
                      teamDetail={matchDetail?.teamB}
                      viewOnly={true}
                      team={"b"}
                    />
                  </div>
                </div>
              )}
              <div className="flex justify-end my-5">
                <div className="w-full md:w-[35%]">
                  <NewMatchCard img={img} color={"#FFD500"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(MatchDetailSection);

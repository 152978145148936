import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosInstance';
import { ERROR, MATCH_STATUS, MATCH_TYPE, SUCCESS } from '../extras/constants';
import { formateTime, isInvalidMatchBeforeStart } from '../extras/utils';
import { addToast } from '../redux/actions/toasts';

const MyMatchesGameCard = ({ match, active, finished }) => {
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  const [isInValidMatch, setisInValidMatch] = useState(true);
  const isOrganized = active === MATCH_TYPE.ORGANIZED;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const navigate = useNavigate();

  const startMatch = () => {
    setIsLoading(true);
    axiosInstance
      .post('/match/start', {
        matchId: match?._id,
      })
      .then((res) => {
        setIsLoading(false);
        queryClient.invalidateQueries(['myMatches', auth.accessToken]);
        queryClient.invalidateQueries('matchesList');
        navigate(`/matchDetail/${match?._id}/update/score`);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: 'Match Started Successfully!',
          })
        );
      })
      .catch((err) => {
        setIsLoading(false);

        dispatch(
          addToast({
            kind: ERROR,
            msg: 'Unable Start Match!',
          })
        );
      });
  };

  useEffect(() => {
    const isValid = isInvalidMatchBeforeStart(match);
    console.log('invalid Match', isValid);
    setisInValidMatch(isValid);
  }, [match]);

  return (
    <>
      <div
        className={`rounded-xl w-full m-auto mb-5 mt-5 py-5 drop-shadow-xl ${
          match?.status === MATCH_STATUS.LIVE
            ? 'bg-[#A3ED72]'
            : match?.status === MATCH_STATUS.UPCOMING
            ? 'bg-[#FFD500] '
            : 'bg-[#F78E4D] '
        }`}
      >
        <div className="flex items-center justify-around">
          <div className="flex items-end">
            {match?.teamA.points > match?.teamB?.points && <EmojiEventsIcon />}
            <div className="flex flex-col items-center ml-2">
              <p className="font-semibold">{match?.teamA.points}</p>
              <p className="font-semibold">{match?.teamA.name}</p>
            </div>
          </div>
          <div>
            <div className="flex">
              {match?.threeByThree ? '3' : '5'}

              <img src="/sword_cross.svg" alt="" />

              {match?.threeByThree ? '3' : '5'}
            </div>
          </div>

          <div className="flex items-end">
            {match?.teamB?.points > match?.teamA.points && <EmojiEventsIcon />}
            <div className="flex flex-col items-center ml-2">
              <p className="font-semibold">{match?.teamB?.points}</p>
              <p className="font-semibold">{match?.teamB?.name}</p>
            </div>
          </div>
        </div>
        <div className="bg-black h-[1px] my-2"></div>
        <div className="flex px-5 justify-between pt-2">
          <div className="flex">
            <CalendarMonthIcon />
            <p className="font-semibold ml-2">
              {new Date(match?.dateAndTime).toLocaleDateString()}
            </p>
          </div>
          <div className="flex">
            <AccessTimeIcon />
            <p className="font-semibold ml-2">
              {formateTime(match?.dateAndTime)}
            </p>
          </div>
        </div>
        <div className="flex px-5 pt-3">
          <LocationOnIcon />
          <p className="font-semibold ml-2">
            {match?.venue} , {match?.city}
          </p>
        </div>
        <div className="px-6">
          <button
            disabled={isLoading || isInValidMatch}
            className="btn-primary  !rounded-full !font-semibold !text-sm mt-4 uppercase"
            onClick={() =>
              match?.status === MATCH_STATUS.UPCOMING
                ? startMatch()
                : navigate(
                    `/matchDetail/${match?._id}/${
                      isOrganized ? (!finished ? 'update/score' : '') : ''
                    }`
                  )
            }
          >
            {match?.status === MATCH_STATUS.LIVE
              ? active === 'organized'
                ? 'UPDATE SCORE'
                : 'View Score'
              : match?.status === MATCH_STATUS.UPCOMING
              ? 'START GAME'
              : 'VIEW DETAILS'}
          </button>

          {match?.status === MATCH_STATUS.UPCOMING && isOrganized && (
            <Link
              onClick={() => window.scrollTo(0, 0)}
              to={`/mymatches/edit/${match?._id}/A`}
              className="text-center appearance-none rounded-full  block w-full mt-4 -mb-2 bg-black border-0 py-2 focus:outline-none text-sm font-semibold text-white"
            >
              EDIT DETAILS
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

export default MyMatchesGameCard;

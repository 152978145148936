import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import axiosInstance from "../../axiosInstance";

function useMutatePlayerStatus() {
  const queryClient = useQueryClient();

  return useMutation(
    (postData) => axiosInstance.post(`/match/changePlayerStatus`, postData),
    {
      onSuccess: (newData) => {
        queryClient.invalidateQueries("getMatch");
        toast.success(`Changed Player Status`);
      },
      onError: (err) => {
        const { response } = err;
        try {
          switch (response.status) {
            case 400:
              //   add a toast
              toast.error("Invalid Data");
              break;
            case 401:
              //   add a toast
              toast.error("Unauthorized");
              break;
            default:
              toast.error("Something went wrong");
              break;
          }
        } catch (e) {
          toast.error("Something went wrong");
        }
      },
    }
  );
}

export default useMutatePlayerStatus;

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import React from 'react';
import { Link } from 'react-router-dom';
import AndroidIcon from '../components/social/AndroidIcon';

function Footer() {
  return (
    <div className="md:container md:pb-10 ">
    </div>
  );
}

export default Footer;

import React from "react";
import { Link } from "react-router-dom";

const NewMatchCard = ({ img, color }) => {
  return (
    <div
      style={{ backgroundColor: color }}
      className={`rounded-xl w-[90%] m-auto mb-7 py-5 drop-shadow-xl flex items-center px-3`}
    >
      <img src={img} alt="" />
      <div>
        <p className="font-semibold">Looking to start a new match?</p>
        <Link
          to="/mymatches/matchtype"
          className="py-1  mt-2 mr-2 px-4 font-medium text-black bg-white rounded-full float-right hover:text-app-secondary transition duration-300"
        >
          New Match
        </Link>
      </div>
    </div>
  );
};

export default NewMatchCard;

import React from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import axiosInstance from "../axiosInstance";
import MyMatchesGameCard from "./MyMatchesGameCard";
import MyMatchesGameCardLoading from "./MyMatchesGameCardLoading";
function Organized() {
  let active = "organized";
  const auth = useSelector((state) => state.auth);
  const fetchMyMatches = async () => {
    const res = await axiosInstance.get(`/match/getMyMatches`);

    return res.data;
  };

  const {
    data: apiResponse,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(["myMatches", auth.accessToken], fetchMyMatches);
  return (
    <div>
      {isLoading ? (
        [0, 1, 2].map((_, index) => <MyMatchesGameCardLoading key={index} />)
      ) : isSuccess ? (
        <>
          {apiResponse?.live.length > 0 &&
            apiResponse.live.map((match, index) => {
              return (
                <MyMatchesGameCard match={match} active={active} key={index} />
              );
            })}
          {apiResponse?.upcoming.length > 0 &&
            apiResponse.upcoming.map((match, index) => {
              return (
                <MyMatchesGameCard match={match} active={active} key={index} />
              );
            })}
          {apiResponse?.finished.length > 0 &&
            apiResponse.finished.map((match, index) => {
              return (
                <MyMatchesGameCard match={match} finished active={active} key={index} />
              );
            })}
          {!apiResponse.live.length &&
            !apiResponse.upcoming.length &&
            !apiResponse.finished.length && (
              <h1 className="text-center mt-10">No organized matches found</h1>
            )}
        </>
      ) : (
        <h1>Something Went Wrong Try again</h1>
      )}
    </div>
  );
}

export default Organized;

import { AccessTime, Clear, Height, Scoreboard } from "@mui/icons-material";
import React from "react";
import { Link } from "react-router-dom";

const AllOptionsCard = ({ matchDetail }) => {
  return (
    <div className="w-[100%] gap-5 items-center justify-center flex flex-wrap rounded-md">
      <Link
        to={`/matchDetail/${matchDetail?._id}/update/score`}
        className="w-[40%]"
      >
        <div className="w-full px-3 py-4 md:p-5 flex flex-col items-center justify-center rounded-md drop-shadow-xl bg-white">
          <Scoreboard fontSize="large" />
          <h1 className="font-semibold text-xl">Add Score</h1>
        </div>
      </Link>
      <Link
        to={`/matchDetail/${matchDetail?._id}/update/foul`}
        className="w-[40%]"
      >
        <div className="w-full px-3 py-4 md:p-5 flex flex-col items-center justify-center rounded-md drop-shadow-xl bg-white">
          <Clear fontSize="large" />
          <h1 className="font-semibold text-xl">Add Foul</h1>
        </div>
      </Link>
      <Link
        to={`/matchDetail/${matchDetail?._id}/update/subsitution`}
        className="w-[40%]"
      >
        <div className="w-full px-3 py-4 md:p-5 flex flex-col items-center justify-center rounded-md drop-shadow-xl bg-white">
          <Height fontSize="large" />
          <h1 className="font-semibold text-xl">Subsitution</h1>
        </div>
      </Link>
      <Link
        to={`/matchDetail/${matchDetail?._id}/update/score`}
        className="w-[40%]"
        onClick={() => alert("Coming Soon")}
      >
        <div className="w-full px-3 py-4 md:p-5 flex flex-col items-center justify-center rounded-md drop-shadow-xl bg-white">
          <AccessTime fontSize="large" />
          <h1 className="font-semibold text-xl">Time Out</h1>
        </div>
      </Link>
    </div>
  );
};

export default AllOptionsCard;

import React, { useEffect, useState } from 'react';
import img from '../assets/basketball-athlete-2.png';
import Header from '../components/Header';

import { connect } from 'react-redux';

import { Height, MonitorWeight } from '@mui/icons-material';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import axiosInstance from '../axiosInstance';
import ProfileSingleCard from '../components/profile/ProfileSingleCard';
import { BASE_URL } from '../extras/constants';
import { camelCaseToTitleCase } from '../extras/utils';
import { showModal } from '../redux/actions/modal';
import { LOGIN_MODAL } from '../extras/constants';

const ProfileSection = ({ auth }) => {
  const { id } = useParams();
  const [active, setActive] = useState('stats');
  const [pointsPerMatch, setPointsPerMatch] = useState(0);
  const [profileStats, setProfileStats] = useState({
    //matchesOrganized: 0,
    //onePointer: 0,
    matchesPlayed: 0,
    twoPointer: 0,
    threePointer: 0,
    pointsPerMatch: 0,
  });

  const fetchProfile = async () => {
    if (id) {
      const res = await axiosInstance.get(
        `${BASE_URL}/player/getProfile/${id}`
      );
      return res.data;
    } else {
      let res = await axiosInstance.get(`${BASE_URL}/player/getProfileByToken`);
      res = await axiosInstance.get(`${BASE_URL}/player/getProfileByToken`);
      return res.data;
    }
  };

  const { data: apiResponse, refetch } = useQuery('fetchProfile', fetchProfile);
  useEffect(() => {
    if (apiResponse?.player) {
      const {
        matchesPlayed,
        numberOfMatches,
        matchesOrganised,
        points: { one, two, three },
      } = apiResponse.player;

      setProfileStats({
        //matchesOrganized: matchesOrganised?.length,
        matchesPlayed: matchesPlayed?.length,
        onePointer: one,
        twoPointer: two,
        threePointer: three,
        totalPoints: parseInt(one + two * 2 + three * 3),
        pointsPerMatch: parseInt(
          (one + two * 2 + three * 3) / (numberOfMatches || 1)
        ),
      });
    }
  }, [apiResponse]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const birthdate = new Date(apiResponse?.player?.dateOfBirth);
  const today = new Date();
  const diffInMs = today - birthdate;
  const age = Math.floor(diffInMs / (1000 * 60 * 60 * 24 * 365.25));

  console.log(apiResponse);
  console.log(profileStats);
  return (
    <div>
      <Header title={'Profile'} />
      <div className="rounded-xl shadow-card m-auto mb-7 drop-shadow-xl bg-white relative">
        {auth.isLoggedIn ? (
          <>
            <div className="flex items-center">
              <div>
                <img src={img} alt="" />
              </div>
              <div>
                <h1 className="font-bold text-md">
                  {apiResponse?.player?.name} | #
                  {apiResponse?.player?.jerseyNumber}
                </h1>
                <h1 className="font-semibold text-sm">
                  {age} years | {apiResponse?.player?.gender}
                </h1>
                {apiResponse?.player?.height == null &&
                  apiResponse?.player?.weight && (
                    <h1 className="font-semibold text-sm">
                      <Height /> {apiResponse?.player?.height}cm |{' '}
                      <MonitorWeight /> {apiResponse?.player?.weight} kg
                    </h1>
                  )}
                <Link
                  to="/updateprofile"
                  className="text-sm underline text-black"
                >
                  Update Profile
                </Link>
              </div>
            </div>
            <div className="mt-3 flex justify-between">
              <button
                onClick={() => setActive('stats')}
                className={`px-2 ${
                  active === 'stats' ? 'border-b-4 border-app-secondary' : ' '
                } font-bold text-center w-full `}
              >
                STATS
              </button>
              <button
                onClick={() => setActive('myTeams')}
                className={`px-2 ${
                  active === 'myTeams' ? 'border-b-4 border-app-secondary' : ' '
                } font-bold text-center w-full `}
              >
                {id ? 'TEAMS' : 'MY TEAMS'}
              </button>
            </div>

            {active === 'myTeams' && (
              <div className="bg-[#eeeeeeb9]">
                <div className="pb-12 pt-5 flex justify-around ">
                  <h1>No teams</h1>
                </div>
              </div>
            )}

            {active === 'stats' && (
              <div className="bg-[#eeeeeeb9] py-7 px-4 gap-4 flex flex-wrap items-stretch">
                {Object.keys(profileStats).map((key, index) => (
                  <ProfileSingleCard
                    label={camelCaseToTitleCase(key)}
                    value={profileStats[key]}
                  />
                ))}
              </div>
            )}
          </>
        ) : (
          <div className="p-3">
            <p className="text-center">Please Login to see Player Profiles</p>
            {/* <button
              onClick={() => {
                console.log('hello');
                showModal({
                  modalType: LOGIN_MODAL,
                });
              }}
              className="m-auto block py-2 px-4 font-medium rounded bg-app-secondary text-white"
            >
              Log In
            </button> */}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

// const mapDispatchToProps = (dispatch) => {
//   return {
//     showModal: (kind) => dispatch(showModal(kind)),
//   };
// };

export default connect(mapStateToProps)(ProfileSection);

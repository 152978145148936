import React from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import axiosInstance from "../axiosInstance";
import MyMatchesGameCard from "./MyMatchesGameCard";
import MyMatchesGameCardLoading from "./MyMatchesGameCardLoading";

function Participated() {
  let active = "participated";
  const auth = useSelector((state) => state.auth);
  const fetchMyMatches = async () => {
    const res = await axiosInstance.get(`/match/getMyParticipatedMatches`);

    return res.data?.result;
  };

  const {
    data: apiResponse,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(["myParticipated", auth.accessToken], fetchMyMatches);
  return (
    <div>
      {isLoading ? (
        [0, 1, 2].map((_, index) => <MyMatchesGameCardLoading key={index} />)
      ) : isSuccess ? (
        <>
          {apiResponse?.length > 0 &&
            apiResponse?.map((match, index) => {
              return (
                <MyMatchesGameCard match={match} active={active} key={index} />
              );
            })}
        </>
      ) : (
        <h1>Something Went Wrong Try again</h1>
      )}
    </div>
  );
}

export default Participated;

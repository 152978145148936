//modal types
export const LOGIN_MODAL = 'LOGIN_MODAL';
export const CREATE_ACCOUNT_MODAL = 'CREATE_ACCOUNT_MODAL';
export const VERIFY_OTP_MODAL = 'VERIFY_OTP_MODAL';
export const PLAYER_EXISTS_MODAL = 'PLAYER_EXISTS_MODAL';
export const DELETE_CONFIRMATION_MODAL = 'DELETE_CONFIRMATION_MODAL';
export const EDIT_PLAYER_MODAL = 'EDIT_PLAYER_MODAL';

// toasts
export const ERROR = 'ERROR';
export const SUCCESS = 'SUCCESS';
export const INFO = 'INFO';
export const WARNING = 'WARNING';
export const DEFAULT = 'DEFAULT';

// api url
export const BASE_URL = process.env.REACT_APP_API_URL;

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const MATCH_TYPE = {
  ORGANIZED: 'organized',
  PARTICIPATED: 'participated',
};
export const MATCH_STATUS = {
  FINISHED: 'finished',
  UPCOMING: 'upcoming',
  LIVE: 'live',
};

import { ArrowBack } from "@mui/icons-material";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../axiosInstance";
const AddSubsitutionCard = ({
  matchDetail,
  playerInfo,
  team,
  setPlayerInfo,
  setTeam,
}) => {
  const [playerIn, setPlayerIn] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { teamA, teamB } = matchDetail;

  const handleAddSubstitute = () => {
    setIsLoading(true);
    const playerInId = playerIn;
    const playerOutId = playerInfo;
    const matchId = matchDetail._id;

    const data = {
      playerInId,
      playerOutId,
      matchId,
      team: team === teamA.name ? "a" : team === teamB.name ? "b" : "",
    };
    axiosInstance
      .post(`/match/addSubstitute`, data)
      .then((res) => {
        setIsLoading(false);
        queryClient.invalidateQueries("getMatch");
        console.log(res);
        toast.success(
          "Substitution Added",
          `Substitution Updated Successfully for team ${team}`
        );
        navigate(`/matchDetail/${matchDetail._id}/update/score`);
        // dispatch(getCurrentMatch(matchDetail._id, setIsLoading));
      })
      .catch((response) => {
        setIsLoading(false);

        console.log(response);
        try {
          switch (response.status) {
            case 400:
              //   add a toast
              toast.error("Error", "Invalid Data");
              break;
            case 401:
              //   add a toast
              toast.error("Error", "Unauthorized");
              break;
            default:
              toast.error("Error", "Something went wrong");
              break;
          }
        } catch (e) {
          toast.error("Error", "Something went wrong");
        }
      });
  };
  return (
    <div className="rounded-md drop-shadow-xl bg-white relative">
      <div className="flex items-center pt-4">
        <Link
          to={`/matchDetail/${matchDetail?._id}/update`}
          className="font-semibold px-4 rounded-full"
        >
          <ArrowBack />
        </Link>
        <h1 className="font-semibold text-center text-xl w-[80%]">
          Add Subsitution
        </h1>
      </div>
      <div className="w-full h-[2px] bg-[#FFD500]"></div>
      <div className="flex items-center justify-center flex-col gap-3 py-4">
        <div className="flex items-center justify-between gap-x-10  w-[80%] ">
          <h1 className="font-semibold text-lg ">Team</h1>
          <select
            value={team}
            onChange={(e) => {
              const value = e.target.value;
              console.log(value);
              setTeam(value);
              value === teamA.name && setPlayerInfo(teamA.players[0]._id);
              value === teamB.name && setPlayerInfo(teamB.players[0]._id);
            }}
            className="px-11 border-2 rounded-full   w-2/3"
          >
            <option value={""}>Select Team</option>

            <option value={teamA.name}>{teamA.name}</option>
            <option value={teamB.name}>{teamB.name}</option>
          </select>
        </div>
        {
          <>
            <div className="flex items-center justify-between gap-x-10   w-[80%] ">
              <label className="font-semibold text-lg ">Out</label>
              <select
                className="px-11 border-2 rounded-full w-2/3"
                value={playerInfo}
                onChange={(e) => {
                  console.log(e.target.value);
                  setPlayerInfo(e.target.value);
                }}
              >
                {team === teamA.name &&
                  matchDetail?.teamA?.players.map(
                    (player, index) =>
                      player.isActive && (
                        <option value={player._id?._id} key={index}>
                          {player.name}
                        </option>
                      )
                  )}
                {team === teamB.name &&
                  matchDetail?.teamB?.players.map(
                    (player, index) =>
                      player.isActive && (
                        <option value={player._id?._id} key={index}>
                          {player.name}
                        </option>
                      )
                  )}
              </select>
            </div>
            <div className="flex items-center justify-between gap-x-10   w-[80%] ">
              <label className="font-semibold text-lg ">In</label>
              <select
                className="px-11 border-2 rounded-full w-2/3"
                value={playerIn}
                onChange={(e) => {
                  console.log(e.target.value);
                  setPlayerIn(e.target.value);
                }}
              >
                <option value={""}>Select Player</option>
                {team === teamA.name &&
                  matchDetail?.teamA?.players.map(
                    (player, index) =>
                      !player.isActive && (
                        <option value={player._id?._id} key={index}>
                          {player.name}
                        </option>
                      )
                  )}
                {team === teamB.name &&
                  matchDetail?.teamB?.players.map(
                    (player, index) =>
                      !player.isActive && (
                        <option value={player._id?._id} key={index}>
                          {player.name}
                        </option>
                      )
                  )}
              </select>
            </div>
          </>
        }
      </div>
      <div className="w-full flex items-center justify-center">
        <button
          type="submit"
          disabled={isLoading}
          onClick={() => handleAddSubstitute()}
          className="btn-primary !w-[80%] rounded-full py-1 text-white my-2"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default AddSubsitutionCard;

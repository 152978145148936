import { useNavigate } from "react-router";
import { whatsappText } from "../hooks/whatsappText";

export default function VenueCard({ venue }) {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        whatsappText(
          `Hi, I visited your website space4all.in and I would like to enquire regarding booking a ${venue}`
        );
      }}
      className="h-full rounded-xl w-full relative bg-center hover:scale-105 active:scale-95 duration-150 cursor-pointer hover:shadow-2xl active:shadow-lg bg-cover"
      style={{
        backgroundImage: `url("https://source.unsplash.com/featured/${venue}")`,
      }}
    >
      <div className="bg-black absolute flex flex-col items-center justify-center text-center rounded-xl top-0 right-0 left-0 bottom-0 bg-opacity-50 hover:bg-opacity-70 duration-150 z-20">
        <p className="text-white font-bold text-2xl">{venue}</p>
      </div>
    </div>
  );
}

import { useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../axiosInstance";
import { getCurrentMatch } from "../redux/actions/auth";
const TeamDetail = ({
  teamDetail,
  setPlayerInfo,
  playerInfo,
  setTeam,
  viewOnly,
  team,
}) => {
  const [playerID, setPlayerID] = useState(null);
  const [isActiveVal, setIsActiveVal] = useState(null);
  const [teamVal, setTeamVal] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { matchId } = useParams();
  const queryClient = useQueryClient();

  // console.log("is acrive val", teamDetail);
  const handlePlayerStatus = (team, playerId, isActive) => {
    setIsLoading(true);
    const data = {
      playerId,
      isActive,
      matchId,
      team,
    };
    console.log("data of player status", data);
    axiosInstance
      .post(`/match/changePlayerStatus`, data)
      .then((res) => {
        setIsLoading(false);

        queryClient.invalidateQueries("getMatch");
        console.log(res);
        toast.success(`Changed Player Status`);
        navigate(`/matchDetail/${matchId}/update/score`);
        dispatch(getCurrentMatch(matchId, setIsLoading));
      })
      .catch((err) => {
        setIsLoading(false);
        const { response } = err;
        try {
          switch (response.status) {
            case 400:
              //   add a toast
              toast.error("Error", "Invalid Data");
              break;
            case 401:
              //   add a toast
              toast.error("Error", "Unauthorized");
              break;
            default:
              toast.error("Error", "Something went wrong");
              break;
          }
        } catch (e) {
          toast.error("Error", "Something went wrong");
        }
      });
  };

  return (
    <div className="w-full rounded-md drop-shadow-xl bg-white">
      <h1 className="font-semibold text-center text-xl pt-4 pl-4">{`Team ${
        teamDetail?.name || teamDetail?.name
      }`}</h1>
      <div className="w-full h-[2px] bg-[#FFD500]"></div>
      <div className="m-auto">
        <table className="w-full border-collapse">
          <tr>
            <th className="text-center p-2 border-r-2 border-b-2 boder-[#ddd]">
              #
            </th>
            <th className="text-center p-2 border-r-2 border-b-2 boder-[#ddd]">
              Jersey No.
            </th>
            <th className="text-center p-2 border-r-2 border-b-2 boder-[#ddd]">
              Player
            </th>
            <th className="text-center p-2 border-r-2 border-b-2 boder-[#ddd]">
              Score
            </th>
            <th className="text-center p-2 border-b-2 boder-[#ddd]">Fouls</th>
          </tr>
          {teamDetail?.players.map((player, index) => (
            <tr
              key={index}
              onClick={() => {
                if (player.isActive) {
                  setTeam(teamDetail.name);
                  setPlayerInfo(player._id?._id);
                }
              }}
              className={`${
                playerInfo === player?._id
                  ? "bg-[#ddd]"
                  : player.isActive && "hover:bg-[#ddd]"
              } ${viewOnly ? "" : "cursor-pointer"}`}
            >
              {/* <td className="text-center p-2 border-r-2 border-[#ddd] ">
                {player?.isActive ? (
                  viewOnly ? (
                    <span className="text-green-400">Active</span>
                  ) : (
                    <ToggleOnRounded
                      style={{ color: "#6ccca5", fontSize: 30 }}
                      className="cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        !isLoading &&
                          handlePlayerStatus(team, player._id?._id, false);
                      }}
                    />
                  )
                ) : viewOnly ? (
                  <span className="text-red-400">Inactive</span>
                ) : (
                  <ToggleOffRounded
                    style={{ color: "#FF9494", fontSize: 30 }}
                    className="cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      !isLoading &&
                        handlePlayerStatus(team, player._id?._id, true);
                    }}
                  />
                )}
              </td> */}
              <td className="text-center p-2 border-r-2 border-[#ddd] cursor-pointer hover:underline ">
                <Link to={`/profile/${player?._id?._id}`}>View</Link>
              </td>
              <td className="text-center p-2 border-r-2 border-[#ddd] ">
                {player?.jerseyNumber
                  ? player?.jerseyNumber
                  : player?._id?.jerseyNumber}
              </td>
              <td className="text-center p-2 border-r-2 border-[#ddd] ">
                {player?.name}
              </td>
              <td className="text-center p-2 border-r-2 border-[#ddd] ">
                {player?.points}
              </td>
              <td className="text-center p-2">{player?.fouls}</td>
            </tr>
          ))}
        </table>
      </div>
    </div>
  );
};

export default TeamDetail;
